import { Nullable } from '../../@types/helper';

export interface IProps {
  environment?: 'development' | 'production';
  sourceId: string;
}

export class BelibitcoinInstant {
  constructor(private readonly props: IProps) {}
  private _connection: Nullable<WebSocket> = null;
  private _orderId: Nullable<string> = null;
  private _pingId: Nullable<NodeJS.Timer> = null;

  private listenUri() {
    if (this.props.environment === 'production' || typeof this.props.environment === 'undefined') {
      return 'wss://socket.belibit.co.in/prod';
    }
    return 'wss://socket2.belibit.co.in/dev';
  }

  private createWs(): WebSocket {
    const uri = this.listenUri();
    const client = new WebSocket(uri);
    return client;
  }

  sourceId(): string {
    return this.props.sourceId;
  }

  orderId(): Nullable<string> {
    return this._orderId;
  }

  close(): boolean {
    if (this._connection === null) {
      return false;
    }
    this._connection.close();
    return true;
  }

  listenStatus(
    orderId: string,
    onMessage: (this: WebSocket, ev: MessageEvent<string>) => unknown,
  ): void {
    if (this._connection !== null) {
      throw new Error('There is an existing connection, please close it first before creating new one');
    }
    this._connection = this.createWs();
    this._connection.onopen = () => {
      const roomId = `${this.sourceId()}:${orderId}`;
      const joinMessage = { action: 'join', roomId };
      (this._connection as WebSocket).send(JSON.stringify(joinMessage));
      this._orderId = orderId;
      console.log(`connected to ${roomId}`);
    };
    this._connection.onmessage = onMessage;
    this._connection.onclose = (_ev) => {
      this._connection = null;
      this._orderId = null;
      try {
        clearInterval(this._pingId as NodeJS.Timer);
      } catch (error) {
        console.error('fail shutting down heartbeat', error);
      }
      this._pingId = null;
    };
    this._pingId = setInterval(() => {
      const roomId = `${this.sourceId()}:${orderId}`;
      const message = { type: 'heartbeat', roomId };
      try {
        (this._connection as WebSocket).send(JSON.stringify(message));
      } catch (error) {
        console.error('heartbeat failure', error);
      }
    }, 5 * 60_000); // heartbeat every 5 minutes
  }
}