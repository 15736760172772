import { Button, Space } from 'antd';
import React from 'react';

const ButtonGroup = () => {
  return (
    <>
      <Space>
        <Button className='button--first'>Fast Transaction</Button>
        <Button className='button--second'>
          <span className='button--second-text'>
            <p className='button--second-text-1'>Average processing time:</p>
            <p className='button--second-text-2'>1 - 20 mins</p>
          </span>
        </Button>
      </Space>

      <style jsx>{`
        .button--first {
          width: 150px;
          height: 50px;
          border-radius: 10px;
          background: rgba(51, 222, 191, 0.2);
          color: #1F9D86;
          font-size: 12px;
          font-weight: 600;
          line-height: 18px;
          border: 0;

        }

        .button--second {
          width: 200px;
          height: 50px;
          border-radius: 10px;
          background: rgba(179, 179, 179, 0.2);
          font-size: 12px;
          line-height: 18px;
          color: #8A8A8A;
          border: 0;
          padding: 6px 15px;
        }

        .button--second-text-1 {
          margin: 0;
          font-weight: 400;
          margin-bottom: 0 !important;
        }

        .button--second-text-2 {
          font-weight: 600;
        }

        .button--first:hover, .button--first:focus, .button--first:active, .button--first:focus {
          background: rgba(51, 222, 191, 0.2);
          color: #1F9D86;
          border: 0;
        }

        .button--second:hover, .button--second:focus, .button--second:active, .button--second:focus {
          background: rgba(179, 179, 179, 0.2);
          color: #8A8A8A;
          border: 0;
        }
      `}
      </style>
    </>
  );
};

export default ButtonGroup;
