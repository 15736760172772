import { Nullable } from '../@types/helper';

interface IPromoConfig {
  /**
   * @description
   * make sure this fits the constraint in MerchantPromo from backend
   */
  value: number;
  network: string;
  inputCurrency: string;
  /**
   * @description
   * filling this with null does not filter the currency but by network alone
   * where filling this with USDT for example will filter the currency, showing only USDT on the specified network
   */
  outputCurrency: Nullable<string>;
  promoSlug: string;
  /**
   * @description
   * For "Buy Now" button
   */
  discountAmount: string;
}

export const PROMO_CONFIG: IPromoConfig = {
  value: 250_000,
  network: 'BSC',
  inputCurrency: 'IDR',
  outputCurrency: 'BNB',
  /**
   * @description
   * for the special page path
   * e.g: asd123 => /buy-crypto/asd123
   * it has higher priority than the one with custom slug for SEO, but better be safe than sorry
   * so make sure it doesn't coincide with the regex there
   */
  promoSlug: 'cb83fed8-7f3d-47ba-9738-31f5f1511a1f',
  discountAmount: '30%',
};