import { Checkbox, message, Spin } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { requestOTP } from '../../apis';
import ButtonCustom from '../../components/ButtonCustom';
import Header from '../../components/Header';
import OrderDetailItem from '../../components/OrderDetailItem';
import { ACTION_TYPES } from '../../state/actionTypes';
import { AppContext } from '../../state/appContext';
import { CHAIN_ID } from '../../constants';
import TextArea from 'antd/lib/input/TextArea';
import { hooks } from '../../components/connectors/metamask';
import { SESSION_STORAGE_IDS } from '../../helper/enums';
import { isWhitelist } from '../../helper/utils';

const { useIsActive,useChainId } = hooks;

const OrderConfirm = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(AppContext);
  const [loading, setLoading] = useState(false);

  const chainId = useChainId();
  const isActive = useIsActive();

  const [isAddressChecked, setIsAddressChecked] = useState(false);
  const [isAckExternal, setIsAckExternal] = useState(true);
  const location = useLocation();
  const { fromPromo } = location.state ?? { fromPromo: false };

  useEffect(() => {
    if (state.outputAmount === null) {
      navigate('/buy-crypto');
    }
  }, []);

  useEffect(() => {
    const cryptoList = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_IDS.CRYPTO_LIST));
    const selected = cryptoList.find(item => item.iso.toUpperCase() === state.outputCurrency.toUpperCase() && item.network === state.outputNetwork);
    if (selected.vaultProvider) {
      setIsAckExternal(false);
    }
  }, [setIsAckExternal, state.outputCurrency, state.outputNetwork]);

  const handlePreviousPage = () => {
    navigate(-1);
  };

  // const getDateNowString = () => {
  //   const date = new Date();
  //   return `${('0' + date.getDate()).slice(-2)}${('0' + (date.getMonth() + 1)).slice(-2)}${date.getFullYear()}`;
  // };

  const createGuid = () => {
    return `${isWhitelist(state.outputCurrency) ? '1' : ''}${Date.now()}-${Math.floor(Math.random() * Math.pow(10, 6))}`;
  };

  const handleNextPage = async () => {
    if (!state.walletAddress) {
      message.error('Please enter your wallet address');
      return;
    }

    if (!isAddressChecked) {
      message.error('Please confirm recipient address is correct!');
      return;
    }

    if (!isAckExternal) {
      message.error('Please confirm you\'ve understand the risk');
      return;
    }

    const whatsappID = sessionStorage.getItem(SESSION_STORAGE_IDS.USER_ID);
    if (!whatsappID) {
      navigate('/login/whatsapp', {
        state: {
          type: 'order',
          fromPromo,
        }
      });
    } else {
      setLoading(true);
      dispatch({ type: ACTION_TYPES.UPDATE_WHATSAPP_ID, payload: whatsappID });
      try {
        const transactionGuid = createGuid();
        await requestOTP(whatsappID, transactionGuid, 'order');
        dispatch({ type: ACTION_TYPES.UPDATE_TRANSACTION_ID, payload: transactionGuid });
        setLoading(false);
        navigate('/login/whatsapp-otp', {
          state: {
            type: 'order',
            fromPromo,
          }
        });
      } catch (error) {
        console.log("Request OTP failed: ", error);
        message.error(error.response.data.error.message);
        setLoading(false);
      }
    }
  };

  const getCurrency = () => {
    const cryptoList = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_IDS.CRYPTO_LIST));
    return cryptoList.find(item => item.iso.toUpperCase() === state.outputCurrency.toUpperCase() && item.network === state.outputNetwork);
  };

  return (
    <Spin spinning={loading}>
      <div className='order-confirm-page'>
        <Header title='Confirm your order' onPreviousPage={handlePreviousPage} />
        <div className='order-confirm__bank-information'>
          <span className='order-confirm__bank-information--text'>Using payment method</span>
          <img className='order-confirm__bank-information--icon' alt='bank-information' src={`/assets/icons/${state.paymentMethod?.toLowerCase()}.svg`} />
        </div>
        <div className='order-confirm-page__content'>
          <div className='input-label'>
            <div className='input-label__text'>
              <p className='input-label__text__left'>To</p>
              <div className='input-label__text__right'>
                {isActive && <p className={`input-label__text__right-0 input-label__text__right-0-${CHAIN_ID[state.outputNetwork] === chainId ? 'green' : 'red'}`}>Metamask</p>}
                <p className={`input-label__text__right-1 text-right-${getCurrency()?.networkName?.toUpperCase()}`}>{getCurrency()?.networkName}</p>
              </div>
            </div>

            <div className='input-label__text-area'>
              <TextArea
                className='input-label__input'
                value={state.walletAddress}
                autoSize
                disabled
              />
              <div className='input-label__icons'>
                <img alt="suffix-icon" src={getCurrency()?.iconUrl?.small} className="suffix-icon" />
              </div>
            </div>
          </div>

          <div className='bnb-page__content__detail'>
            <p className='bnb-page__content__detail__title'>ORDER DETAILS</p>
            <OrderDetailItem leftText={`${state.outputAmount} ${state.outputCurrency} @ ${state.rate}`} rightText={`${((+state.outputAmount) * (+state.rate)).toLocaleString()} ${state.inputCurrency}`} />
            {
              state.fees.map((fee, i) => {
                return (<OrderDetailItem key={i} leftText={fee.name} rightText={`${(+fee.amount).toLocaleString()} ${fee.currency}`} />);
              })
            }
            <OrderDetailItem leftText="Total" rightText={`${Math.round((+state.totalFeeAmount + (+state.outputAmount) * (+state.rate))).toLocaleString()} ${state.inputCurrency}`} />
          </div>

          <div className='order-confirm-page__terms'>
            <div>
              <Checkbox checked={isAddressChecked} onChange={(e) => setIsAddressChecked(e.target.checked)}>
                I confirm recipient address is correct. This address able to receive <strong>{state.outputCurrency}</strong> in <strong>{getCurrency()?.networkName} NETWORK</strong>
              </Checkbox>
            </div>
            <div>
              {getCurrency().vaultProvider &&
                <Checkbox checked={isAckExternal} onChange={(e) => setIsAckExternal(e.target.checked)}>
                  I understand my fund will be deposited into vault operated by <a href={getCurrency()?.vaultUrl} target='_blank' rel='noreferrer'>{getCurrency()?.vaultProvider}</a>. I have review and accept all {getCurrency()?.riskUrl ? <a href={getCurrency()?.riskUrl} target='_blank' rel='noreferrer'>risks</a> : 'risks'} associated with this vault.
                </Checkbox>
              }
            </div>
          </div>

        </div>

        <ButtonCustom onClick={handleNextPage}>Continue</ButtonCustom>

        <style jsx>{`
        .order-confirm-page {
          padding: 24px;
          min-height: 100%;
          display: flex;
          flex-direction: column;
          background: #ffffff;
        }

        .order-confirm-page__content {
          margin-top: 15px;
          flex-grow: 1;
        }

        .bnb-page__content__detail {
          margin-top: 24px;
        }

        .bnb-page__content__detail__title {
          font-weight: 600;
          color: #777777;
        }

        .ant-spin-nested-loading, .ant-spin-container {
          height: 100%;
        }

        .order-confirm__bank-information {
          display: flex;
          flex-direction: column;
          margin-top: 24px;
        }

        .order-confirm__bank-information--text {
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          color: #000000;
        }

        .order-confirm__bank-information--icon {
          width: 119px;
          height: 37px;
          margin-top: 16px;
        }

        .input-label {
          margin-top: 16px;
        }
        .input-label__text {
          font-size: 12px;
          display: flex;
          justify-content: space-between;
        }

        .input-label__text p {
          margin-bottom: 6px;
        }

        .input-label__text__left {
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          color: #000000;
        }

        .input-label__text__right {
          display: flex;
          flex-direction: row;
        }

        .input-label__icons {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: center;
        }

        .input-label__icons img {
          height: 25px;
        }

        .input-label__text-area {
          display: flex;
          border: 1px solid #E7E7E7;
          justify-content: center;
          align-items: center;
          padding: 4px 8px 4px 0;
          border-radius: 10px;
          min-height: 50px;
        }

        .input-label__text-area > textarea.ant-input, .input-label__text-area > textarea.ant-input:focus {
          border: none;
          box-shadow: none;
          background: #ffffff;
        }

        .input-label__text__right-0 {
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          background: #CAFBEC;
          border-radius: 4px;
          color: #2FD6A4;
          padding: 2px 8px;
          border-radius: 4px;
        }

        .input-label__text__right-1 {
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          color: #E7BA15;
          margin-left: 8px;
          background: #FFF6D6;
          padding: 2px 8px;
          border-radius: 4px;
        }

        .text-right-BSC {
          color: #E7BA15;
          background: #FFF6D6;
        }

        .text-right-ETHEREUM {
          color: #6F7BBA;
          background: #D8DDF7;
        }

        .text-right-POLYGON {
          color: #8247E5;
          background: #E6D7FF;
        }

        .text-right-BITCOIN {
          color: #F7931A;
          background: #FFEAD0;
        }

        .text-right-MAINNET {
          color: #28244A;
          background: #C8C5DD;
        }

        .text-right-ZIL {
          color: #29CCC4;
          background: #C8FFFC;
        }

        .text-right-SOLANA {
          color: #1BDA8E;
          background: #E3FFF3;
        }

        .text-right-AVAXCHAIN {
          color: #E84142;
          background: #FFE3E4;
        }

        .text-right-OPTIMISM {
          color: #C91529;
          background: #FFBEC5;
        }

        .text-right-ALGORAND {
          color: #242424;
          background: #D0D0D0;
        }

        .text-right-ARBITRUM {
          color: #26A0F2;
          background: #D9E3F5;
        }


        .input-label__text__right {
          display: flex;
          flex-direction: row;
        }

        .input-label__text__right-0-red {
          color:#FFFFFF;
          background:#F07B7B;
        }

        .input-label__text__right-0-green {
          color:#2FD6A4;
          background:#CAFBEC;
        }

        .order-confirm-page__terms {
          padding: 16px;
          background: rgba(239, 239, 239, 0.2);
          border: 1px solid #E7E7E7;
          border-radius: 10px;

          margin-top: 24px;
        }

        .ant-checkbox-wrapper {
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;

          color: #777777;
        }
      `}
        </style>
      </div>
    </Spin>
  );
};

export default OrderConfirm;
