import React, { useReducer } from "react";
import "./App.css";
import OrderInfo from "./pages/OrderInfo";
import LoginWhatsapp from "./pages/LoginWhatsapp";
import { BuyCrypto } from "./pages/BuyCrypto";
import LoginWhatsappOtp from "./pages/LoginWhatsappOtp";
import { AppContext } from "./state/appContext";
import { appReducer } from "./state/appReducer";
import { initialState } from "./state/initialState";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import SelectCurrency from "./pages/SelectCurrency";
import Menu from "./pages/Menu";
import TransactionOrderNumber from "./pages/TransactionOrderNumber";
import TransactionCompleted from "./pages/TransactionCompleted";
import 'react-phone-number-input/style.css';
import FloatingWhatsApp from "react-floating-whatsapp";
import VerifyWhatsapp from "./pages/VerifyWhatsapp";
import { MetaMaskProvider } from "metamask-react";
import OrderConfirm from "./pages/OrderConfirm";
import { Helmet, HelmetData } from 'react-helmet-async';
import { CommonNinjaWidget } from 'commonninja-react';
import ReactGA from 'react-ga4';
import { P2POrder } from './pages/P2POrder';
import { BuyCryptoPromo } from './pages/BuyCrypto/promo';
import { PROMO_CONFIG } from './constants/promo';

function App() {
  const [state, dispatch] = useReducer(appReducer, initialState);
  const helmetData = new HelmetData({});
  const { promoSlug } = PROMO_CONFIG;

  ReactGA.initialize('G-PP7V9PV1BL');

  return (
    <MetaMaskProvider>
      <Helmet helmetData={helmetData}>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta name="description" content="Explore the best peer-to-peer crypto marketplace at Beli Finance. Buy Bitcoin, Ethereum, USDT, and other digital assets directly from sellers. Enjoy a secure and user-friendly P2P experience in decentralized finance." />
        <meta name="google-site-verification" content="q8_dQY6ps89v2rYhi_mZWnoYd0a5QmWnLCa_dgLsS9U" />
        <title>{'Best P2P Bitcoin Crypto Marketplace | Beli Bitcoin, Ethereum, USDT & Digital Assets | Beli Finance'}</title>
      </Helmet>
      <BrowserRouter>
        <CommonNinjaWidget
          widgetId='3e68f42c-0f2c-4b49-b723-e27f489415f2'
        />
        <AppContext.Provider value={{ state, dispatch }}>
          <div className="App">
            <Routes>
              <Route index element={<Navigate replace to="/buy-crypto" />} />
              <Route path="buy-crypto" element={<BuyCrypto />} />
              <Route path={`buy-crypto/${promoSlug}`} element={<BuyCryptoPromo/>}></Route>
              <Route path="buy-crypto/:slug" element={<BuyCrypto />} />
              <Route path="order-info" element={<OrderInfo />} />
              <Route path="login/whatsapp" element={<LoginWhatsapp />} />
              <Route path="login/whatsapp-otp" element={<LoginWhatsappOtp />} />
              <Route path="select-currency" element={<SelectCurrency />} />
              <Route path="menu" element={<Menu />} />
              <Route path="transaction/ordernumber" element={<TransactionOrderNumber />} />
              <Route path="transaction/ordernumber/:orderId" element={<TransactionOrderNumber />} />
              <Route path="tx/order" element={<P2POrder />}/>
              <Route path="tx/order/:orderId" element={<P2POrder />} />
              <Route path="transaction/completed" element={<TransactionCompleted />} />
              <Route path="login/whatsapp-verification" element={<VerifyWhatsapp />} />
              <Route path="order-confirm" element={<OrderConfirm />} />
              <Route path="*" element={<Navigate replace to="/buy-crypto" />} />
            </Routes>

            <FloatingWhatsApp
              phoneNumber="62816308037"
              accountName="Beli Finance"
              avatar="/assets/images/avatar.ico"
              allowClickAway
              notificationDelay={60000} // 1 minute
              notificationSound />
          </div>
        </AppContext.Provider>
      </BrowserRouter>
    </MetaMaskProvider>
  );
}

export default App;
