import { Radio } from 'antd';
import React, { useContext } from 'react';
import { AppContext } from '../../state/appContext';

const PaymentRadio = ({ value, icon }) => {
  const { state } = useContext(AppContext);

  return (
    <div className={`payment-radio ${state.paymentMethod === value ? 'checked' : ''}`}>
      <Radio value={value} size="small">
        <img className={`menu__content__item__left__icon icon-${value}`} alt='menu-item-icon' src={`${window.location.origin}/assets/icons/${icon}.svg`} />
      </Radio>

      <style jsx>
        {`
          .payment-radio {
            background: #FFFFFF;
            border: 1px solid #E7E7E7;
            border-radius: 8.21957px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .ant-radio-inner {
            width: 12px;
            height: 12px;
            margin-left: 2px;
          }

          .ant-radio-wrapper {
            margin-right: 0;
          }

          .ant-radio-wrapper > span:last-child {
            margin-right: 5px;
            margin-left: 5px;
            padding: 0;
          }

          .ant-radio-inner:after {
            transform: scale(0.4) !important;
          }

          .checked {
            border: 2px solid #3385FF;
            box-shadow: 0px 0px 10px rgba(51, 133, 255, 0.2);
          }

          .ant-radio-input:focus + .ant-radio-inner {
            box-shadow: none;
          }

          .menu__content__item__left__icon {
            height: 20px;
          }

          .icon-BCA {
            height: 15px;
          }
        `}
      </style>
    </div>
  );
};

export default PaymentRadio;
