import React, { createContext } from 'react';
import { AppState, ReducerAction } from './interfaces';

export type AppContextType = {
  state: AppState;
  dispatch: React.Dispatch<ReducerAction>;
};

// will be filled by reducer
export const AppContext = createContext<AppContextType>({} as unknown as AppContextType);
