export enum BELIBIT_INSTANT_STATUS {
  WAITING_DEPOSIT = 'WAITING_DEPOSIT',
  DELIVERED = 'DELIVERED',
  COMPLETED = 'COMPLETED',
  PROCESSING = 'PROCESSING',
  CONVERTED = 'CONVERTED',
}

export enum P2P_STATUS {
  INITIATED = 'INITIATED',
  EXPIRED = 'EXPIRED',
  ACCEPTED = 'ACCEPTED',
  PAID = 'PAID',
  DISPUTED = 'DISPUTED',
  CANCELLED = 'CANCELLED',
  RECEIVED = 'RECEIVED',
  PROCESSING = 'PROCESSING',
  CONVERTED = 'CONVERTED',
  WAITING_DEPOSIT = 'WAITING_DEPOSIT',
  INQUEUE = 'INQUEUE',
  DELIVERED = 'DELIVERED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  REVERTED = 'REVERTED',
  CONTACT_SUPPORT = 'CONTACT_SUPPORT',
}