import React, { useContext, useState } from 'react';
import ButtonGroup from '../../components/ButtonGroup';
import Header from '../../components/Header';
import TimeLine from '../../components/TimeLine';
import { useNavigate, useParams } from 'react-router-dom';
import { Spin } from 'antd';
import { AppContext } from '../../state/appContext';
import ButtonCustom from '../../components/ButtonCustom';
import { Helmet, HelmetData } from 'react-helmet-async';
import { CommonNinjaWidget } from 'commonninja-react';
import { Country } from '../../constants/interfaces';

export const BuyCrypto = (): React.JSX.Element => {
  const { state } = useContext(AppContext);
  const params = useParams();
  // e.g: indonesia-rupiah-BSC-IDR-USDT
  const match = params.slug?.match(/(?<network>BSC|POLYGON|ETH|BTC|ARBITRUM|BASE)-(?<inputCurrency>[^-]{3})-(?<outputCurrency>.+)$/i);
  const { network, inputCurrency, outputCurrency } = match?.groups ?? {};
  const [loading, setLoading] = useState(false);
  const helmetData = new HelmetData({});

  const navigate = useNavigate();

  const handleNextPage = () => {
    navigate('/order-info');
  };

  return (
    <Spin spinning={loading}>
      {outputCurrency && <Helmet helmetData={helmetData}>
        <meta name="description" content={`Securely buy ${outputCurrency} and other digital assets using peer-to-peer (P2P) with ease on Beli Finance. Explore a reliable platform for hassle-free transactions and seamless acquisition of digital assets.`} />
        <title>{`Buy ${outputCurrency} | Best P2P Bitcoin Crypto Marketplace | Beli Finance`}</title>
      </Helmet>}
      <div className='home-page'>
        <Header title='Beli Crypto P2P ke Wallet DeFi' />
        <div className='home-page--content'>
          <TimeLine
            className='home-page--timeline'
            setLoading={setLoading}
            defaultInputCurrency={(inputCurrency as Country['id'])?.toUpperCase()}
            defaultNetwork={network?.toUpperCase()}
            defaultOutputCurrency={outputCurrency?.toUpperCase()}
          />
          <div className='home-page__button-group'>
            <ButtonGroup />
          </div>
        </div>
        <ButtonCustom onClick={handleNextPage} disabled={state.outputAmount === null || state.outputAmount === ''}>
          Buy Now
        </ButtonCustom>

        <div className='partner__content'>
          <span className='fivestar__content'>
            <img className='fivestar__img' alt='p2p bitcoin five star reviews' src='/assets/icons/five-star-reviews.svg' />
          </span>
          <span>
            <a target='_blank' href='https://p2p.beli.finance/blog/beli-ledger-nano-hardware-wallet-resmi-indonesia/' rel="noreferrer"><img className='partner__img' alt='p2p bitcoin' src='/assets/icons/ledger.svg' /></a>
            <a target='_blank' href='https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn' rel="noreferrer"><img className='partnermetamask__img' alt='p2p bitcoin' src='/assets/icons/metamask.svg' /></a>
          </span>

        </div>

        <div >
          <CommonNinjaWidget
            widgetId='e8d22088-774e-4b93-bf78-9efd8e4ed357'
          />
        </div>

        <div>
          <CommonNinjaWidget
            widgetId='ad7a50d8-40b6-48a2-b3dc-41a52909ca21'
          />
        </div>

        <style jsx>{`
        .home-page {
          padding: 24px;
          min-height: 100%;
          display: flex;
          flex-direction: column;
          background: #fff;
        }

        .home-page--content {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
        }
        .home-page__button-group {
          margin-top: 36px;
        }

        .home-page p {
          margin-bottom: 2px;
        }

        .partner__content {
        display: flex;
        flex-direction: column;
        align-items: left;
        text-align: center;

        flex-grow: 100;
        margin-top:32un
        px;
        }

       .fivestar__content {
        margin-top:40px;
        margin-bottom:15px;
        }

        .fivestar__img {
        width: 180px;
        }

       .partner__img {
        width: 110px;
        padding-right:20px;
        }

        .partnermetamask__img {
        width: 150px;
        }

        [ant-click-animating-without-extra-node='true']::after{
          display:none;
        }

        .ant-spin-nested-loading, .ant-spin-container {
          height: 100%;
        }
      `}
        </style>
      </div>
    </Spin>
  );
};
