import React, { useContext, useState, useEffect } from 'react';
import { message, Radio, RadioChangeEvent, Space, Timeline } from 'antd';
import TimeLineDot from '../TimeLineDot';
import { ACTION_TYPES } from '../../state/actionTypes';
import { AppContext } from '../../state/appContext';
import { getCryptoList, predict, reportReferralView } from '../../apis';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { COUNTRIES, DEFAULT_INPUT_VALUE, PAYMENT_METHODS } from '../../constants';
import { ArrowsAltOutlined, ShrinkOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import PaymentRadio from '../PaymentRadio';
import { BigNumber } from 'bignumber.js';
import { SESSION_STORAGE_IDS } from '../../helper/enums';
import { CryptoList } from '../../apis/interfaces';
import { Country } from '../../constants/interfaces';
import { Nullable } from '../../@types/helper';

interface IProps {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  className?: string;
  defaultInputCurrency?: Country['id'];
  defaultNetwork?: string;
  defaultOutputCurrency?: Nullable<CryptoList['iso']>;
  defaultAmount?: string;
  disableChange?: boolean;
  fromPromo?: boolean;
}

export interface ITimelineLocation {
  showMinimumError?: boolean;
}

const TimeLine = ({
  setLoading,
  className,
  defaultInputCurrency,
  defaultNetwork,
  defaultOutputCurrency,
  defaultAmount,
  disableChange,
  fromPromo = false,
}: IProps): React.JSX.Element => {
  const { state, dispatch } = useContext(AppContext);
  const [inputValue, setInputValue] = useState(state.inputAmount);
  const [showCalculation, setShowCalculation] = useState(false);
  const [cryptoList, setCryptoList] = useState<CryptoList[]>([]);
  const [firstLoading, setFirstLoading] = useState(true);
  const [isReferral, setIsReferral] = useState(false);

  const [query] = useSearchParams();

  const location = useLocation();
  const { showMinimumError } = (location.state ?? {}) as ITimelineLocation;

  const navigate = useNavigate();

  useEffect(() => {
    if (!isReferral) {
      setIsReferral(true);
      const ref = query.get('ref');
      if (ref) {
        dispatch({
          type: ACTION_TYPES.UPDATE_REFERRAL,
          payload: ref,
        });
        reportReferralView(ref);
      }
    }
  }, []);

  useEffect(() => {
    async function fetchData() {
      const cryptoList = sessionStorage.getItem(SESSION_STORAGE_IDS.CRYPTO_LIST);
      if (!cryptoList) {
        try {
          const response = await getCryptoList();
          sessionStorage.setItem(SESSION_STORAGE_IDS.CRYPTO_LIST, JSON.stringify(response.data));
          setCryptoList(response.data);
        } catch (_error) {
          /* empty */
        }
      } else {
        setCryptoList(JSON.parse(cryptoList));
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    if (!state.isDefaultSet && cryptoList.length > 0) {
      const redeemCode = query?.get('coupon');
      const inputCurrency = COUNTRIES.find((x) => {
        return x.id === (defaultInputCurrency ?? 'IDR');
      }) as Country;
      const inputAmount = defaultAmount ?? DEFAULT_INPUT_VALUE[defaultInputCurrency ?? 'IDR'];
      const outputCurrency = cryptoList.find((x) => {
        const matchCurrency = x.iso === (defaultOutputCurrency ?? 'USDT');
        const matchNetwork = x.network === (defaultNetwork ?? 'BSC');
        return matchCurrency && matchNetwork;
      }) as CryptoList;
      dispatch({
        type: ACTION_TYPES.UPDATE_REDEEM_CODE,
        payload: redeemCode,
      });
      dispatch({
        type: ACTION_TYPES.UPDATE_OUTPUT_CURRENCY,
        payload: outputCurrency,
      });
      dispatch({
        type: ACTION_TYPES.UPDATE_CURRENCY_DEFAULT,
        payload: true,
      });
      dispatch({
        type: ACTION_TYPES.UPDATE_INPUT_CURRENCY,
        payload: inputCurrency,
      });
      dispatch({
        type: ACTION_TYPES.UPDATE_INPUT,
        payload: inputAmount,
      });
      setInputValue(inputAmount);
    }
  }, [cryptoList]);

  useEffect(() => {
    dispatch({
      type: ACTION_TYPES.UPDATE_OUTPUT_AMOUNT,
      payload: null,
    });

    dispatch({
      type: ACTION_TYPES.UPDATE_USD_VALUE,
      payload: null
    });

    const handleChange = async () => {
      if (!inputValue) {
        return;
      }

      dispatch({
        type: ACTION_TYPES.UPDATE_INPUT,
        payload: inputValue
      });

      setLoading(true);
      const inputCurrency = state.inputCurrency;
      const outputCurrency = state.outputCurrency;
      const outputNetwork = state.outputNetwork;

      try {
        const whatsappID = sessionStorage.getItem(SESSION_STORAGE_IDS.USER_ID) as string;
        const data = await predict({
          inputCurrency,
          inputAmount: inputValue,
          outputCurrency,
          outputNetwork,
          whatsappID,
          redeemCode: state.redeemCode,
        });
        if (fromPromo && !data.data.isSpecialPrice) {
          throw new Error('Jatah harga spesial sudah habis');
        }
        if (!inputValue.match(/^\d+$/)) {
          throw new Error('invalid input amount');
        }
        if (parseFloat(inputValue) < parseFloat(data.data.constraints.minimum)) {
          if (firstLoading && showMinimumError) {
            setInputValue(data.data.constraints.minimum);
          } else {
            dispatch({
              type: ACTION_TYPES.UPDATE_ERROR,
              payload: `Minimum buy amount should be more than ${(+data.data.constraints.minimum).toLocaleString()} ${data.data.constraints.currency}`
            });
          }
        } else if (parseFloat(inputValue) > parseFloat(data.data.constraints.maximum)) {
          dispatch({
            type: ACTION_TYPES.UPDATE_ERROR,
            payload: `Please place an order of less than ${parseFloat(data.data.constraints.maximum).toLocaleString()} ${data.data.constraints.currency}`
          });
        } else {
          dispatch({
            type: ACTION_TYPES.UPDATE_INPUT_AMOUNT, payload: {
              inputAmount: inputValue,
              outputAmount: data.data.receive.amount,
              minerFee: `${data.data.minerFee.amount} ${data.data.minerFee.currency}`,
              totalFee: `${parseFloat(data.data.totalFee.amount).toLocaleString()} ${data.data.totalFee.currency}`,
              fees: data.data.fees,
              price: data.data.price,
              totalFeeAmount: data.data.totalFee.amount,
              usdValue: data.data.receive.usdValue?.amount
            }
          });
        }
        setFirstLoading(false);
        setLoading(false);
      } catch (error) {
        console.log((error as Error).message);
        message.error((error as Error).message);
        dispatch({
          type: ACTION_TYPES.UPDATE_OUTPUT_AMOUNT,
          payload: null,
        });
        setLoading(false);
      }
    };

    const timer = setTimeout(async () => {
      await handleChange();
    }, 500);

    return () => clearTimeout(timer);
  }, [inputValue, showMinimumError, state.inputCurrency, state.outputCurrency, state.outputNetwork, state.redeemCode]);



  const handleShowCalculation: React.MouseEventHandler<HTMLElement> = (e) => {
    e.preventDefault();
    setShowCalculation(!showCalculation);
  };

  const handleShowSelectCountries: React.MouseEventHandler<HTMLElement> = (_e) => {
    dispatch({ payload: undefined, type: ACTION_TYPES.UPDATE_REDEEM_CODE });
    navigate('/select-currency', {
      state: {
        items: COUNTRIES,
        type: ACTION_TYPES.UPDATE_INPUT_CURRENCY,
        title: 'Select a fiat currency',
        searchLabel: 'Search currency',
        fromPromo,
      }
    });
  };

  const getFilteredCurrencyList = () => {
    return cryptoList.filter((c) => {
      const matchNetwork = c.network === defaultNetwork;
      const matchCurrency = defaultOutputCurrency ? c.iso === defaultOutputCurrency : true;
      return matchNetwork && matchCurrency;
    });
  };

  const handleShowSelectCurrency: React.MouseEventHandler<HTMLElement> = (e) => {
    dispatch({ payload: undefined, type: ACTION_TYPES.UPDATE_REDEEM_CODE });
    navigate('/select-currency', {
      state: {
        items: fromPromo ? getFilteredCurrencyList() : cryptoList,
        type: ACTION_TYPES.UPDATE_OUTPUT_CURRENCY,
        title: 'Select crypto currency',
        searchLabel: 'Search crypto',
        fromPromo,
      }
    });
  };

  const handleChangePaymentMethod = (e: RadioChangeEvent) => {
    dispatch({
      type: ACTION_TYPES.UPDATE_PAYMENT_METHOD,
      payload: e.target.value
    });
  };

  const getCurrency = (currency: string, network: string): Country | CryptoList => {
    return cryptoList.find(item => item.iso.toUpperCase() === currency.toUpperCase() && item.network === network) as CryptoList || COUNTRIES.find(item => item.id === currency) as Country;
  };

  const prettyDecimal = (value: Nullable<string | number>, precision: number) => {
    if (typeof value === 'undefined') return '';
    if (value === null) return '';
    if (value === '') return '';

    const bnValue = new BigNumber(value);
    const prec = bnValue.integerValue().toString().length >= 6 ? 5 : precision;
    if (bnValue.eq(0)) return bnValue.toFixed(prec, BigNumber.ROUND_DOWN);

    const decimalString = bnValue.toFixed(prec, BigNumber.ROUND_DOWN);
    const match = decimalString.match(/\./);
    // if contains dot (.), clean up trailing zeroes, otherwise return as is
    if (match === null) return decimalString;
    if (decimalString.match(/\.0+$/)) decimalString.replace(/\.0+$/, '');
    return decimalString.replace(/0+$/, '');
  };

  return (
    <div className='time-line'>
      <Timeline className={className}>
        <Timeline.Item
          dot={
            <TimeLineDot
              title='Kamu bayar'
              value={inputValue as string}
              onChange={(e) => setInputValue(e)}
              url={getCurrency(state.inputCurrency, state.outputNetwork)?.iconUrl?.small}
              currency={state.inputCurrency}
              onShowCurrency={handleShowSelectCountries}
              error={state.error}
              disableInputChange={disableChange}
              disableSelectorChange={fromPromo && COUNTRIES.length <= 1}
            />
          }
          className='time-line--item-custom-dot-head'
        />
        <Timeline.Item className='custom-icon-dot'>
          <span>Cara pembayaran</span>
          <div className='payment-method'>
            <Radio.Group onChange={handleChangePaymentMethod} value={state.paymentMethod}>
              <Space wrap size={8}>
                {PAYMENT_METHODS.map(((paymentMethod, index) => (
                  <PaymentRadio key={index} value={paymentMethod.value} icon={paymentMethod.icon} />
                )))}
              </Space>
            </Radio.Group>
          </div>
        </Timeline.Item>
        <Timeline.Item
          className='time-line__text custom-icon-dot'
          dot={showCalculation ? <ShrinkOutlined /> : <ArrowsAltOutlined />}
        >
          <span className='time-line__calculation' onClick={handleShowCalculation}>{showCalculation ? 'Tutup Kalkulasi' : 'Lihat Kalkulasi'}</span>
        </Timeline.Item>
        {showCalculation &&
          state.fees.map((fee, i) => {
            return (<Timeline.Item key={i} className='custom-icon-dot'>
              <span className='time-line--text-title-dot'>{(+fee.amount).toLocaleString()} {fee.currency}</span>
              <span className='time-line__text'>{fee.name}</span>
            </Timeline.Item>);
          })
        }
        <Timeline.Item className='custom-icon-dot' dot={<MinusOutlined />}>
          <span className='time-line--text-title-dot'>{state.totalFee}</span>
          <span className='time-line__text'>Total Fees</span>
        </Timeline.Item>
        <Timeline.Item className='timeline--item-next-tail custom-icon-dot' dot={<PlusOutlined />}>
          <span className='time-line--text-title-dot'>{state.price}</span>
          <span className='time-line__text'>Rate</span>
        </Timeline.Item>
        <Timeline.Item
          dot={
            <TimeLineDot
              className='large-button'
              title='Kamu terima (estimasi)'
              value={state.error.length === 0 ? prettyDecimal(state.outputAmount, 8) : ''}
              onShowCurrency={handleShowSelectCurrency}
              url={getCurrency(state.outputCurrency, state.outputNetwork)?.iconUrl?.small}
              currency={getCurrency(state.outputCurrency, state.outputNetwork)?.name}
              network={(getCurrency(state.outputCurrency, state.outputNetwork) as CryptoList)?.networkName}
              vaultProvider={(getCurrency(state.outputCurrency, state.outputNetwork) as CryptoList)?.vaultProvider}
              apy={(getCurrency(state.outputCurrency, state.outputNetwork) as CryptoList)?.apy}
              type={(getCurrency(state.outputCurrency, state.outputNetwork) as CryptoList)?.type}
              outputUsd={`${state.usdValue ? state.usdValue + ' USD' : ''}`}
              showOutput={true}
              disableInputChange={disableChange}
              disableSelectorChange={fromPromo && getFilteredCurrencyList().length <= 1}
            />
          }
          className='time-line--item-custom-dot-tail'
        />
      </Timeline>

      <style jsx>{`
        .ant-timeline-item-content {
        }

        .time-line {
          margin-top: 60px;
          padding-left: 30px;
        }

        .time-line--item-custom-dot-head {
          height: 85px;
        }

        .time-line--item-custom-dot-tail {
          height: 70px;
        }

        .time-line--item-custom-dot-head > .ant-timeline-item-head {
          transform: translate(-10%, -50%);
        }

        .time-line--item-custom-dot-tail > .ant-timeline-item-head {
          transform: translate(-10%, -20%);
        }

        .timeline--item-next-tail {
          height: 55px;
        }

        .ant-timeline-item-content {
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
        }

        .time-line--item-custom-dot-head > .ant-timeline-item-content {
          height: 0;
        }

        .time-line--item-custom-dot-tail > .ant-timeline-item-content {
          height: 0;
          min-height: 0;
        }

        .time-line__calculation {
          font-weight: 600;
          cursor: pointer;
          color: #3385FF;
        }

        .time-line__text {
          color: #C4C4C4;
        }

        .custom-icon-dot {
          color: #777777;
        }

        .custom-icon-dot > .ant-timeline-item-head-custom {
          background: #E7E7E7;
          border-radius: 50%;
          color: #777777;
          padding: 3px;
        }

        .custom-icon-dot > .ant-timeline-item-head-blue {
          border: none;
          background: #E7E7E7;
        }

        .payment-method {
          display: flex;
          margin-top: 8px;
        }

        .ant-timeline-item {
          padding-bottom: 24px;
        }

        .time-line--text-title-dot {
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: #777777;
          margin-right: 16px;
        }
      `}
      </style>
    </div>
  );
};

export default TimeLine;
