import { Input, message, Select, Spin } from 'antd';
import React, { useContext, useEffect } from 'react';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { requestOTP, requestOTPP2P } from '../../apis';
import ButtonCustom from '../../components/ButtonCustom';
import Header from '../../components/Header';
import { ACTION_TYPES } from '../../state/actionTypes';
import { AppContext } from '../../state/appContext';
import { DIAL_CODE_LIST } from '../../constants';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { isWhitelist } from '../../helper/utils';

const LoginWhatsapp = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(AppContext);
  const [areaCode, setAreaCode] = useState(String(state.whatsappID).slice(0, 3) || '+62');
  const [number, setNumber] = useState(String(state.whatsappID).slice(3));
  const [loading, setLoading] = useState(false);

  const location = useLocation();

  useEffect(() => {
    const whatsappNumber = `${areaCode}${number.replace(/^0/, '')}`;
    dispatch({ type: ACTION_TYPES.UPDATE_WHATSAPP_ID, payload: whatsappNumber });
  }, []);

  // const getDateNowString = () => {
  //   const date = new Date();
  //   return `${('0' + date.getDate()).slice(-2)}${('0' + (date.getMonth() + 1)).slice(-2)}${date.getFullYear()}`;
  // };

  const createGuid = () => {
    return `${isWhitelist(state.outputCurrency) ? '1' : ''}${Date.now()}-${Math.floor(Math.random() * Math.pow(10, 6))}`;
  };

  const handleNextPage = async () => {
    if (!state.whatsappID) {
      return message.error('Please enter your WhatsApp number');
    }

    if (!isValidPhoneNumber(state.whatsappID)) {
      return message.error('Please enter a valid phone number');
    }

    setLoading(true);
    try {
      const transactionGuid = createGuid();
      /**
       * @type {Awaited<ReturnType<typeof requestOTP | p2pOTP>>}
       */
      let response;
      if (location.state?.type === 'login' || location.state === null) {
        response = await requestOTP(state.whatsappID, transactionGuid, location.state?.type ?? 'login');
      } else {
        response = await requestOTPP2P(state.whatsappID, transactionGuid);
      }
      // const response = await requestOTP(state.whatsappID, transactionGuid, location.state ? location.state.type : 'login');
      dispatch({ type: ACTION_TYPES.UPDATE_TRANSACTION_ID, payload: transactionGuid });
      setLoading(false);
      if (response.data.verificationRequired) {
        navigate('/login/whatsapp-verification', {
          state: {
            type: location.state ? location.state.type : 'login',
            orderId: location.state ? location.state.orderId : null,
            fromPromo: location.state?.fromPromo ?? false,
          }
        });
      } else {
        navigate('/login/whatsapp-otp', {
          state: {
            type: location.state ? location.state.type : 'login',
            orderId: location.state ? location.state.orderId : null,
            fromPromo: location.state?.fromPromo ?? false,
          }
        });
      }
    } catch (error) {
      message.error(error.response.data.error.message);
      console.log("Request OTP failed: ", error);
      setLoading(false);
    }
  };

  const handlePreviousPage = () => {
    navigate(-1);
  };

  const setWhatsappNumber = (value) => {
    setNumber(value);
    const whatsappNumber = `${areaCode}${value.replace(/^0/, '')}`;
    dispatch({ type: ACTION_TYPES.UPDATE_WHATSAPP_ID, payload: whatsappNumber });
  };

  const handleChangeAreaCode = (value) => {
    setAreaCode(value);
    const whatsappNumber = `${value}${number}`;
    dispatch({ type: ACTION_TYPES.UPDATE_WHATSAPP_ID, payload: whatsappNumber });
  };

  return (
    <Spin spinning={loading}>
      <div className='enter-whatsapp-page'>
        <Header title='Verify Whatsapp' onPreviousPage={handlePreviousPage} />

        <div className='input-label'>
          <p className='input-label__text'>Whatsapp Number</p>
          <Input.Group size="large" compact className='input-compact'>
            <Select value={`${areaCode}`} onChange={handleChangeAreaCode} disabled={DIAL_CODE_LIST.length < 2}>
              {DIAL_CODE_LIST.map((dialCode, index) => {
                return <Select.Option key={index} value={dialCode.dialCode}>{dialCode.dialCode}</Select.Option>;

              })}
            </Select>
            <Input allowClear type='number' value={number} onChange={(e) => setWhatsappNumber(e.target.value)} placeholder="8111555555" />
          </Input.Group>
        </div>

        <ButtonCustom disabled={!number} onClick={handleNextPage}>Verify phone number</ButtonCustom>

        <style jsx>{`
        .enter-whatsapp-page {
          padding: 24px;
          min-height: 100%;
          display: flex;
          flex-direction: column;
        }

        .enter-whatsapp-page > .input-label {
          flex-grow: 1;
        }

        .enter-whatsapp-page p {
          margin-bottom: 6px;
        }

        .input-label {
          margin-top: 16px;
        }

        .input-label__text {
          font-size: 12px;
          display: flex;
          justify-content: space-between;
          font-weight: 500;
        }

        .input-label__input {
          border-radius: 10px;
          border: 1px solid #E7E7E7;
          height: 50px;
        }

        .input-compact {
          display: flex !important;
        }

        .ant-select-selection-item {
          display: flex;
          align-items: center;
          color: #000000;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
        }

        .ant-spin-nested-loading, .ant-spin-container {
          height: 100%;
        }

        .PhoneInputInput {
          border: 1px solid #E7E7E7;
          border-radius: 10px;
          height: 50px;
          padding: 14px 16px;
          color: #777777;
        }

        .PhoneInputCountryIcon {
          font-size: 40px;
        }

        .PhoneInputInput:focus {
          outline: none !important;
        }

        .ant-select-selector {
          height: 50px !important;
          border-radius: 10px 0 0 10px !important;
          background: #EFEFEF !important;
          opacity: 0.5;
        }

        .ant-input-affix-wrapper {
          border-radius: 0 10px 10px 0 !important;
          opacity: 0.5;
        }

        .ant-spin-nested-loading, .ant-spin-container {
          height: 100%;
        }

        input[type=number]::-webkit-inner-spin-button,
        input[type=number]::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      `}
        </style>
      </div>
    </Spin>
  );
};

export default LoginWhatsapp;
