import { Country } from './interfaces';
import { PAYMENT_STEPS } from './paymentSteps';

export const COUNTRIES: Country[] = [
  {
    id: 'IDR',
    name: 'Indonesia Rupiah',
    iconUrl: {
      small: '/assets/images/IDR.png'
    },
    network: 'IDR'
  },
  // {
  //   id: 'ARS',
  //   name: 'Argentina Peso',
  //   iconUrl: {
  //     small: '/assets/images/IDR.png',
  //   },
  //   network: 'ARS',
  // },
];

export const MENU_ITEMS = [
  {
    icon: 'trade',
    name: 'Trade',
    link: '/buy-crypto',
    target: '_self'
  },
  {
    icon: 'privacy',
    name: 'Privacy Policy',
    link: 'https://docs.beli.finance/instant-fiat-onramp/privacy-policy',
    target: '_blank'
  },
  {
    icon: 'terms',
    name: 'Terms of Service',
    link: 'https://docs.beli.finance/instant-fiat-onramp/terms-of-service',
    target: '_blank'
  },
  {
    icon: 'login',
    name: 'Login',
    link: 'login/whatsapp',
    target: '_self'
  },
];

export const PAYMENT_METHODS = [
  {
    name: 'Bank Central Asia (BCA)',
    icon: 'bca',
    value: 'BCA'
  },
  {
    name: 'Bank Mandiri',
    icon: 'mandiri',
    value: 'MANDIRI'
  },
  {
    name: 'Bank Permata',
    icon: 'permata',
    value: 'PERMATA'
  },
  // {
  //   icon: 'maybank',
  //   value: 'MAYBANK'
  // },
  {
    name: 'Bank Mandiri',
    icon: 'bersama',
    value: 'BERSAMA'
  },
];

export const DIAL_CODE_LIST = [
  {
    id: 'IDR',
    name: 'Indonesia',
    dialCode: '+62'
  },
];

export const CHAIN_ID = {
  BSC: 56,
  ETH: 1,
  POLYGON: 137,
  BASE: 8453,
  ARBITRUM: 42161,
};

export const URL_TRANSACTION_DETAIL = {
  BSC: "https://bscscan.com/tx/",
  POLYGON: "https://polygonscan.com/tx/",
  ETH: "https://etherscan.io/tx/",
  BTC: "https://mempool.space/tx/",
  BASE: "https://basescan.org/tx/",
  ARBITRUM: "https://arbiscan.io/tx/",
};

export const DEFAULT_INPUT_VALUE: { [k: typeof COUNTRIES[number]['id']]: string } = {
  IDR: (1_000_000).toString(),
  ARS: (55_000).toString(),
};

export { PAYMENT_STEPS };