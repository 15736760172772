import { DownOutlined } from '@ant-design/icons';
import { Button, Input, Space } from 'antd';
import React from 'react';
import { Nullable } from '../../@types/helper';

interface IProps {
  className?: string;
  title: string;
  value: string;
  onChange?: (v: string) => void;
  onShowCurrency: React.MouseEventHandler<HTMLElement>;
  currency: string;
  error?: string;
  url: string;
  network?: string;
  vaultProvider?: Nullable<string>;
  apy?: Nullable<string>;
  type?: string;
  outputUsd?: string;
  showOutput?: boolean;
  disableInputChange?: boolean;
  disableSelectorChange?: boolean;
}

const TimeLineDot = ({
  className = '',
  title,
  value,
  onChange,
  onShowCurrency,
  currency,
  error,
  url,
  network,
  vaultProvider,
  apy,
  type,
  outputUsd,
  showOutput = false,
  disableInputChange = false,
  disableSelectorChange = false,
}: IProps): React.JSX.Element => {

  const handleChangeInputAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e.target.value);
    }
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'e' || e.key === '-' || e.key === '+') {
      e.preventDefault();
      return false;
    }
  };

  return (
    <>
      <Space>
        <div className='time-line-dot'>
          <p className='timeline--item-text'>{title}</p>
          {!showOutput && <Input
            disabled={disableInputChange}
            className={`timeline--item-input ${className}`}
            type='number'
            onKeyDown={onKeyDown}
            value={value}
            onChange={handleChangeInputAmount}
            status={error && error.length !== 0 ? 'error' : 'warning'}>
          </Input>}
          {showOutput && <div className={`timeline--item-input timeline--item-output ${className}`}>
            <p className='output-amount'>{value}</p>
            <p className='output-usd'>{outputUsd}</p>
          </div>}
        </div>
        <Button
          className={`timeline--item-button ${className}`}
          onClick={onShowCurrency}
          disabled={disableSelectorChange}
        >
          <div className='timeline--item-button-content'>
            <div>
              {url && <img alt='currency-icon' src={url} />}
              {currency}
            </div>
            <DownOutlined />
          </div>
          {network && <p className={`timeline--item__button-network timeline--item__button__network-${network.toUpperCase()}`}>{network}</p>}
          {(vaultProvider || apy || type) && <div className='timeline--item__button-bottom'>
            {vaultProvider ? <p className='timeline--item__button-bottom__vault-provider'>{vaultProvider}</p> : <div />}
            <div className='timeline--item__button-bottom__left'>
              {apy && <p className='timeline--item__button-bottom__apy'>{(parseFloat(apy) * 100).toString().substring(0, 4)}%</p>}
              {type && <p className='timeline--item__button-bottom__type'>{type === 'LP' ? 'APY' : type === 'SINGLE' ? '' :  type }</p>}
            </div>
          </div>}
        </Button>

      </Space>

      <div className='error-input'>
        {error}
      </div>

      <style jsx>{`
        .ant-space {
          align-items: flex-end;
        }
        .time-line-dot {
          text-align: left;
          color: #000;
        }
        .timeline--item-input {
          width: 200px;
          height: 60px;
          border-radius: 10px;
          font-size: 16px;
          font-weight: 600;
        }

        .error-input {
          font-size: 10px;
          color: #F07B7B;
          margin-top: 4px;
          text-align: start;
          font-weight: 400;
          line-height: 15px;
        }

        input[type=number]::-webkit-inner-spin-button,
        input[type=number]::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        .timeline--item-button {
          width: 150px;
          height: 60px;
          border-radius: 10px;
          font-size: 16px;
          font-weight: 700;
          padding: 10px 8px;
        }

        .timeline--item-button p {
          margin-bottom: 6px;
        }

        .timeline--item-text {
          font-size: 12px;
          font-weight: 600;
          line-height: 18px;
        }

        .timeline--item-button-content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
        }

        .timeline--item-button-content > .anticon-down {
          align-self: center;
          font-size: 16px;
          font-weight: 700;
        }

        .timeline--item-button-content img {
          width: 24px;
          margin-right: 8px;
        }

        .timeline--item-button-content > span > svg {
          font-size: 8px;
          color: #3385FF;
        }

        .timeline--item__button-network {
          font-weight: 400;
          font-size: 10px;
          line-height: 12px;
          color: #6F7BBA;
          background: #D8DDF6;
          border-radius: 4px;
          padding: 2px 8px;
          width: fit-content;
          margin-top: 6px;
        }
        .timeline--item__button-bottom__vault-provider {
          padding: 2px 8px;
          background: #EFEFEF;
          border-radius: 4px;
          font-weight: 400;
          font-size: 10px;
          line-height: 12px;
          width: fit-content;
          color: #8A8A8A;
        }

        .timeline--item__button-bottom__apy {
          font-weight: 400;
          font-size: 14px;
          line-height: 15px;
          margin-right: 8px;
          color: #000000;
        }

        .timeline--item__button-bottom__type {
          font-weight: 400;
          font-size: 14px;
          line-height: 15px;
          color: #777777;
        }

        .timeline--item__button-bottom {
          display: flex;
          justify-content: space-between;
        }

        .timeline--item__button-bottom__left {
          display: flex;
        }

        .large-button {
          height: 85px;
          width: 200px;
        }

        .timeline--item-output {
          border: 1px solid #E7E7E7;
          border-radius: 10px;
          padding: 11px 16px;
        }

        .output-amount {
          font-weight: 500;
          font-size: 16px;
          line-height: 21px;
          flex-grow: 1;
          padding-top: 6px;
        }

        .output-usd {
          font-weight: 400;
          font-size: 10px;
          line-height: 15px;
          color: #777777;
          margin-bottom: 0;
        }

        .timeline--item-output {
          display: flex;
          flex-direction: column;
        }

        .timeline--item-output {
          width: 150px;
        }

        .timeline--item__button__network-ETHEREUM {
          color: #6F7BBA;
          background: #D8DDF7;
        }

        .timeline--item__button__network-BASE {
          color: #6F7BBA;
          background: #D8DDF7;
        }

        .timeline--item__button__network-MAINNET {
          color: #28244A;
          background: #C8C5DD;
        }

        .timeline--item__button__network-ZIL {
          color: #29CCC4;
          background: #C8FFFC;
        }

        .timeline--item__button__network-POLYGON {
          color: #8247E5;
          background: #E6D7FF;
        }

        .timeline--item__button__network-BSC {
          color: #E7BA15;
          background: #FFF6D6;
        }

        .timeline--item__button__network-SOLANA {
          color: #1BDA8E;
          background: #E3FFF3;
        }

        .timeline--item__button__network-AVAXCHAIN {
          color: #E84142;
          background: #FFE3E4;
        }

        .timeline--item__button__network-OPTIMISM {
          color: #C91529;
          background: #FFBEC5;
        }

        .timeline--item__button__network-ALGORAND {
          color: #242424;
          background: #D0D0D0;
        }

        .timeline--item__button__network-ARBITRUM {
          color: #26A0F2;
          background: #D9E3F5;
        }

        .timeline--item__button__network-BITCOIN {
          color: #F7931A;
          background: #FFEAD0;
        }
      `}
      </style>
    </>
  );
};

export default TimeLineDot;
