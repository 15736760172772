import { Input, Select } from 'antd';
import React, { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import { ACTION_TYPES } from '../../state/actionTypes';
import { AppContext } from '../../state/appContext';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useEffect } from 'react';
import { uniqBy } from 'lodash';
import { PROMO_CONFIG } from '../../constants/promo';

const SelectCurrency = () => {
  const navigate = useNavigate();

  const { dispatch } = useContext(AppContext);

  const location = useLocation();

  const { items, type, title, searchLabel, fromPromo } = location.state ?? { fromPromo: false };
  const { promoSlug } = PROMO_CONFIG;

  const [filteredItems, setFilteredItems] = useState(items);

  const [networkList, setNetworkList] = useState(['ALL NETWORKS']);

  const [searchText, setSearchText] = useState('');

  const [currentSelectedNetwork, setCurrentSelectedNetwork] = useState('ALL NETWORKS');

  useEffect(() =>{
    if (!items) {
      navigate('/buy-crypto');
    }
  }, []);

  useEffect(() => {
    items?.forEach(item => {
      if (item.network && !networkList.includes(item.network)) {
        setNetworkList(networkList => [...networkList, item.network]);
      }
    });
  }, [items, networkList]);

  const handlePreviousPage = () => {
    const path = fromPromo ? `/buy-crypto/${promoSlug}` : '/buy-crypto';
    navigate(path, {
      state: {
        showMinimumError: true,
        fromPromo,
      }
    });
  };

  const onSelectedCurrency = (value) => {
    dispatch({ type, payload: value });
    const path = fromPromo ? `/buy-crypto/${promoSlug}` : '/buy-crypto';
    navigate(path, {
      state: {
        showMinimumError: true,
        fromPromo,
      }
    });
  };

  const handleChangeNetwork = (value) => {
    setCurrentSelectedNetwork(value);
    setSearchText('');
    if (value === 'ALL NETWORKS') {
      setFilteredItems(items);
      return;
    }
    const filteredItems = items.filter(item => item.network === value);
    setFilteredItems(filteredItems);
  };

  const handleChangeSearchText = (e) => {
    setSearchText(e.target.value);
    setCurrentSelectedNetwork('ALL NETWORKS');
    const filteredItems = items.filter(item => item.name.toLowerCase().includes(e.target.value.toLowerCase()));
    setFilteredItems(filteredItems);
  };

  return (
    <div className='select'>
      <Header title={title} onPreviousPage={handlePreviousPage} />
      <div className='select__input'>
        <Input placeholder={searchLabel} value={searchText} onChange={handleChangeSearchText} />
        {type === ACTION_TYPES.UPDATE_OUTPUT_CURRENCY &&
          <Select placeholder="ALL NETWORKS" onChange={handleChangeNetwork} value={currentSelectedNetwork}>
            {uniqBy(networkList).map((item, index) => (
              <Select.Option key={index} value={item}>{item}</Select.Option>
            ))}
          </Select>}
      </div>

      <div className='select__item__container'>
        <InfiniteScroll
          dataLength={9}
          scrollableTarget="scrollableDiv"
        >
          {filteredItems?.map((item, index) => (
            <div className='select__item' key={index} onClick={() => onSelectedCurrency(item)}>
              <div className='select__item__left'>
                <img className='select-item-icon' alt='select-item-icon' src={`${item?.iconUrl?.small}`} />
                <div className='select__item__left__text'>
                  <span className='select__item__left__text--name'>{item.name}</span>
                  <span className='select__item__left__text--description'>{item.description}</span>
                </div>
              </div>
              <div className='select__item__right'>
                <div className='select__item__right-other'>
                  {item.apy && <p className='timeline--item__button-bottom__apy'>{(item.apy * 100).toString().substring(0, 4)}%</p>}
                  {item.type && <p className='timeline--item__button-bottom__type'>{item.type === 'LP' ? 'APY' : item.type === 'SINGLE' ? '' : item.type}</p>}
                  {item.vaultProvider && <p className='timeline--item__button-bottom__vault-provider'>{item.vaultProvider}</p>}
                </div>
                {item.networkName && <span className={`select-item__network select-item__network-${item.networkName?.toUpperCase()}`}>{item.networkName}</span>}
              </div>
            </div>
          ))}
        </InfiniteScroll>
      </div>

      <style jsx>
        {`
          .select {
            padding: 24px;

            display: flex;
            flex-direction: column;
            height: 100%;
          }

          .select__input {
            display: flex;
            flex-direction: row;
            margin-top: 24px;
          }

          .select__input > input {
            margin-right: 14px;
          }

          .select__input > .ant-input {
            background: #EEEEEE;
            border-radius: 10px;
            height: 50px;
            flex: 1;
          }

          .select__input > .ant-select {
            border-radius: 10px;
            height: 50px;
            flex: 1;
          }

          .select__input > .ant-select .ant-select-selector {
            height: 50px;
          }

          .select__item {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #EEEEEE;
            padding: 12px 0;
            cursor: pointer;
          }

          .select__item:hover {
            background: #F8F8F8;
          }

          .select__item__left {
            display: flex;
            flex-direction: row;
            align-items: center;
          }

          .select__item__left > img {
            margin-right: 16px;
          }

          .select-item-icon {
            width: 40px;
            height: 40px;
          }

          .select-item__network {
            border: 1px solid #EFEFEF;
            border-radius: 8px;
            padding: 4px 8px;
            font-weight: 400;
            font-size: 10px;
            line-height: 12px;
            align-self: end;
            width: fits-content;
          }

          .select__item__right {
            display: flex;
            flex-direction: column;
          }

          .select__item__right-other {
            display: flex;
            flex-direction: row;
            margin-bottom: 4px;
            align-items: center;
            justify-content: flex-end;
          }

          .select__item__right-other p {
            margin-bottom: 0;
          }

          .select-item__network-ETHEREUM {
            color: #6F7BBA;
            background: #D8DDF7;
          }

          .select-item__network-BASE {
            color: #6F7BBA;
            background: #D8DDF7;
          }

          .select-item__network-MAINNET {
            color: #28244A;
            background: #C8C5DD;
          }

          .select-item__network-ZIL {
            color: #29CCC4;
            background: #C8FFFC;
          }

          .select-item__network-POLYGON {
            color: #8247E5;
            background: #E6D7FF;
          }

          .select-item__network-BSC {
            color: #E7BA15;
            background: #FFF6D6;
          }

          .select-item__network-SOLANA {
            color: #1BDA8E;
            background: #E3FFF3;
          }

          .select-item__network-AVAXCHAIN {
            color: #E84142;
            background: #FFE3E4;
          }

          .select-item__network-OPTIMISM {
            color: #C91529;
            background: #FFBEC5;
          }

          .select-item__network-ALGORAND {
            color: #242424;
            background: #D0D0D0;
          }

          .select-item__network-ARBITRUM {
            color: #26A0F2;
            background: #D9E3F5;
          }

          .select-item__network-BITCOIN {
            color: #F7931A;
            background: #FFEAD0;
          }

          .timeline--item__button-bottom__apy {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            margin-right: 8px;
            color: #000000;
          }

          .timeline--item__button-bottom__type {
            font-weight: 400;
            font-size: 10px;
            line-height: 15px;
            margin-right: 8px;
            color: #777777;
          }

          .timeline--item__button-bottom__vault-provider {
            padding: 2px 8px;
            background: #EFEFEF;
            border-radius: 4px;
            font-weight: 400;
            font-size: 10px;
            line-height: 12px;
            color: #8A8A8A;
          }

          .select__item__container {
            overflow: hidden;
            margin-top: 12px;
          }

          .infinite-scroll-component__outerdiv {
            height: 100%;
          }

          .infinite-scroll-component {
            height: 100% !important;
            padding-right: 16px;
          }

          .ant-select-selection-placeholder, .ant-select-selection-item {
            align-items: center;
            display: flex;
            opacity: 0.5;
            color: #000000;
          }

          .select__item__left__text {
            display: flex;
            flex-direction: column;
          }

          .select__item__left__text--name {
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
          }

          .select__item__left__text--description {
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            color: #777777;
          }
      `}
      </style>
    </div>
  );
};

export default SelectCurrency;
