import { CloseCircleOutlined } from '@ant-design/icons';
import { Button, message, Modal, Spin } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ButtonCustom from '../../components/ButtonCustom';
import Header from '../../components/Header';
import InputLabel from '../../components/InputLabel';
import OrderDetailItem from '../../components/OrderDetailItem';
import { ACTION_TYPES } from '../../state/actionTypes';
import { AppContext } from '../../state/appContext';
import { validate } from 'bitcoin-address-validation';
import { hooks, metaMask } from '../../components/connectors/metamask';
import { Helmet, HelmetData } from 'react-helmet-async';
import { SESSION_STORAGE_IDS } from '../../helper/enums';

const { useIsActive } = hooks;

const OrderInfo = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(AppContext);
  const [isShowError, setIsShowError] = useState(false);
  const [loading] = useState(false);

  const isActive = useIsActive();
  const regex = new RegExp(/^0x[a-fA-F0-9]{40}$/);
  const helmetData = new HelmetData({});
  const location = useLocation();

  const { fromPromo } = location.state ?? { fromPromo: false };

  const setWalletAddress = (value) => {
    dispatch({ type: ACTION_TYPES.UPDATE_WALLET_ADDRESS, payload: value });
  };

  // attempt to connect eagerly on mount
  useEffect(() => {
    void metaMask.connectEagerly().catch(() => {
      console.debug('Failed to connect eagerly to metamask');
    });
  }, []);

  useEffect(() => {
    if (state.outputAmount === null) {
      navigate('/buy-crypto');
    }
  }, []);

  const handlePreviousPage = () => {
    navigate(-1);
  };

  const handleNextPage = async () => {
    if (!state.walletAddress) {
      message.error('Please enter your wallet address');
      return;
    }
    if (state.outputNetwork === 'BTC') {
      if (!validate(state.walletAddress)) {
        setIsShowError(true);
        return;
      }
    } else {
      if (!regex.test(state.walletAddress)) {
        setIsShowError(true);
        return;
      }
    }

    navigate('/order-confirm', {
      state: {
        fromPromo,
      },
    });
  };

  const getCurrency = () => {
    const cryptoList = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_IDS.CRYPTO_LIST));
    return cryptoList.find(item => item.iso.toUpperCase() === state.outputCurrency.toUpperCase() && item.network === state.outputNetwork);
  };

  const hasShowDisclaimer = () => {
    const cryptoList = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_IDS.CRYPTO_LIST));
    return cryptoList.filter(item => item.iso.toUpperCase() === state.outputCurrency.toUpperCase()).length > 1;
  };

  return (
    <Spin spinning={loading}>
      <Helmet helmetData={helmetData}>
        <meta name="description" content={`Buy ${getCurrency()?.type === 'LP' ? getCurrency()?.vaultProvider.toUpperCase() : getCurrency()?.name} via P2P to DeFi Wallet | Buy Decentralized tokens - Beli Finance`} />
        <title>{`Buy ${getCurrency()?.type === 'LP' ? getCurrency()?.vaultProvider.toUpperCase() : getCurrency()?.name} via P2P to DeFi Wallet | Buy Decentralized tokens - Beli Finance`}</title>
      </Helmet>
      <div className='bnb-page'>
        <Modal visible={isShowError} closable={false} footer={false} centered onCancel={() => setIsShowError(false)}>
          <div className='modal-error'>
            <CloseCircleOutlined />
            <span>Invalid wallet address</span>
            <Button type="primary" size='large' onClick={() => setIsShowError(false)}>OK</Button>
          </div>
        </Modal>
        <Header title={`Buy ${getCurrency()?.type === 'LP' ? getCurrency()?.vaultProvider.toUpperCase() : getCurrency()?.name} to your wallet`} onPreviousPage={handlePreviousPage} />
        {hasShowDisclaimer() && <div className='disclaimer'>
          <p>We are supporting multiple networks of {state.outputCurrency}. You are about to buy {state.outputCurrency} on <strong>{state.outputNetwork}</strong> network
            . If you are not sure what this means then we suggest to go back and change the network in the cryptocurrency selection.</p>
        </div>}
        <div className='bnb-page__content'>
          <InputLabel
            leftText={`${getCurrency()?.type === 'LP' ? getCurrency()?.vaultProvider.toUpperCase() : getCurrency()?.name} Wallet address`}
            rightTexts={state.outputNetwork === 'BTC' ? [`${getCurrency()?.networkName.toUpperCase()} NETWORK`] : isActive ? ['Metamask', `${getCurrency()?.networkName.toUpperCase()} NETWORK`] : ['Connect', `${getCurrency()?.networkName.toUpperCase()} NETWORK`]}
            value={state.walletAddress}
            onChange={setWalletAddress}
            placeholder={`Enter your ${getCurrency()?.name} wallet address`}
            suffixIcon={getCurrency()?.iconUrl?.small}
            disabled={isActive && state.outputNetwork !== 'BTC'}
          />

          <div className='bnb-page__content__detail'>
            <p className='bnb-page__content__detail__title'>ORDER DETAILS</p>
            <OrderDetailItem leftText={`${state.outputAmount} ${state.outputCurrency} @ ${state.rate}`} rightText={`${((+state.outputAmount) * (+state.rate)).toLocaleString()} ${state.inputCurrency}`} />
            {
              state.fees.map((fee, i) => {
                return (<OrderDetailItem key={i} leftText={fee.name} rightText={`${(+fee.amount).toLocaleString()} ${fee.currency}`} />);
              })
            }
            <OrderDetailItem leftText="Total" rightText={`${Math.round((+state.totalFeeAmount + (+state.outputAmount) * (+state.rate))).toLocaleString()} ${state.inputCurrency}`} />
          </div>
        </div>

        <ButtonCustom onClick={handleNextPage}>{`Buy ${getCurrency()?.type === 'LP' ? getCurrency()?.vaultProvider.toUpperCase() : getCurrency()?.name}`}</ButtonCustom>

        <style jsx>{`
        .bnb-page {
          padding: 24px;
          min-height: 100%;
          display: flex;
          flex-direction: column;
          background: #ffffff;
        }

        .bnb-page__content {
          margin-top: 15px;
          flex-grow: 1;
        }

        .bnb-page__content__detail {
          margin-top: 24px;
        }

        .bnb-page__content__detail__title {
          font-weight: 600;
          color: #777777;
        }

        .modal-error {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        .modal-error > span > svg {
          font-size: 70px;
          color: red;
          margin-bottom: 24px;
        }

        .modal-error > button {
          margin-top: 24px;
        }

        .ant-modal {
          width: 390px !important;
        }

        .ant-spin-nested-loading, .ant-spin-container {
          height: 100%;
        }

        .disclaimer {
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          background-color: #fdf1d3;
          border-color: #fdebc2;
          color: #806013;
          padding: 12px 20px;

          margin-top: 15px;
        }

        .disclaimer > p {
          margin-bottom: 0;
        }

        .ant-modal-content {
          border-radius: 20px;
        }

        .ant-modal-body button {
          background: #3385FF;
          border-radius: 40px;
          width: 150px;
        }
      `}
        </style>
      </div>
    </Spin>
  );
};

export default OrderInfo;
