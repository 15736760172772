import React from 'react';
import { ArrowLeftOutlined, MenuOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

/**
 * @param {{ title: string; onPreviousPage?: React.MouseEvetHandler<HTMLElement> }} param0
 * @returns
 */
const Header = ({ title, onPreviousPage }) => {
  const navigate = useNavigate();
  return (
    <div className='header'>
      <div className='header-left-icon' onClick={onPreviousPage}>
        {onPreviousPage && <ArrowLeftOutlined/>}
      </div>
      <div className='header-title'>{title}</div>
      <div className='header-right-icon'>
        <MenuOutlined onClick={() => navigate('/menu')} />
      </div>

      <style jsx>{`
        .header {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }

        .header-title {
          font-size: 18px;
          font-weight: 600;
          line-height: 27px;
        }

        .header-left-icon {
          cursor: pointer;
        }
        `}
      </style>
    </div>
  );
};

export default Header;
