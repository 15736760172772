import axios from 'axios';
import { LambdaAPIResponse, PredictAPIBody, PredictParams, Conversion, CryptoList, InstantInitiateAPIBody, InitiateBelibitInstantParams, InitiateP2PBody, P2PResponse, OTPResponse } from './interfaces';
import { BELIBIT_INSTANT_STATUS, P2P_STATUS } from './enums';
import { isWhitelist, matchWhitelist } from '../helper/utils';

const API = axios.create({ baseURL: 'https://api.belibit.co.in/v1/' });

export async function predict({
  inputAmount,
  inputCurrency,
  outputCurrency,
  outputNetwork,
  whatsappID,
  redeemCode,
}: PredictParams): Promise<LambdaAPIResponse<Conversion<BELIBIT_INSTANT_STATUS.WAITING_DEPOSIT>, unknown>> {
  let body: PredictAPIBody = {
    auth: {
      sourceId: isWhitelist(outputCurrency) ? 'ntp' : "telegram"
    },
    conversion: {
      input: {
        currency: inputCurrency,
        target: inputAmount
      },
      output: {
        currency: outputCurrency,
        network: outputNetwork
      }
    }
  };

  if (whatsappID ) {
    body = {...body, auth: {...body.auth, phoneNumber: whatsappID}};
  }
  if (redeemCode) {
    body = { ...body, voucher: { redeemCode, currency: outputCurrency }};
  }
  const response = await API.post('rpc/instant/predict', body);
  return response.data;
}

export async function getCryptoList(): Promise<LambdaAPIResponse<CryptoList[], unknown>> {
  const response = await API.get('rpc/instant/crypto-list');
  return response.data;
}

export async function initiateBelibitInstant({
  inputAmount,
  inputCurrency,
  outputCurrency,
  outputNetwork,
  whatsappID,
  redeemCode,
  otp,
  transactionId,
  tag,
  walletAddress,
  refCode,
}: InitiateBelibitInstantParams): Promise<LambdaAPIResponse<Conversion<BELIBIT_INSTANT_STATUS.WAITING_DEPOSIT>, unknown>> {
  const body: InstantInitiateAPIBody = {
    auth: {
      phoneNumber: whatsappID,
      sourceId: matchWhitelist(transactionId) ? 'ntp' : "telegram",
      userId: whatsappID,
      username: "felix"
    },
    otp: otp,
    identifier: transactionId,
    conversion: {
      id: transactionId,
      input: {
        currency: inputCurrency,
        network: "BANK_TRANSFER",
        tag: tag,
        target: inputAmount
      },
      output: {
        currency: outputCurrency,
        address: walletAddress,
        network: outputNetwork
      },
    },
    refCode,
  };
  if (redeemCode) {
    body.voucher = {
      redeemCode,
      currency: outputCurrency,
    };
  }
  const response = await API.post('rpc/instant/initiate', body);
  return response.data;
}

export async function reportReferralView(refCode: string): Promise<unknown> {
  const body = {
    ref: refCode,
  };
  const response = await API.post('rpc/referral/view', body);
  return response.data;
}

export async function initiateP2P({
  whatsappID,
  otp,
  transactionId,
  inputCurrency,
  inputAmount,
  outputCurrency,
  walletAddress,
  outputNetwork
}: InitiateP2PBody): Promise<LambdaAPIResponse<P2PResponse<P2P_STATUS.INITIATED>, unknown>> {
  const body = {
    auth: {
      phoneNumber: whatsappID,
      otp,
    },
    conversion: {
      input: {
        currency: inputCurrency,
        amount: inputAmount,
      },
      output: {
        currency: outputCurrency,
      },
    },
    receiver: {
      address: walletAddress,
      network: outputNetwork,
    },
    identifier: transactionId,
  };
  const response = await API.post('/rpc/p2p/initialize/buy', body);
  return response.data;
}

export async function requestOTPP2P(
  phoneNumber: string,
  transactionId: string,
): Promise<LambdaAPIResponse<OTPResponse, unknown>> {
  const body = {
    auth: {
      phoneNumber,
    },
    identifier: transactionId,
  };
  const response = await API.post('/rpc/p2p/otp', body);
  return response.data;
}

export async function requestOTP(
  phoneNumber: string,
  transactionId: string,
  type: 'login' | 'order' = 'login',
): Promise<LambdaAPIResponse<OTPResponse, unknown>> {
  const body = {
    auth: {
      phoneNumber: phoneNumber,
      sourceId: "telegram",
      type: type,
    },
    identifier: transactionId
  };
  const response = await API.post('rpc/instant/otp', body);
  return response.data;
}

export async function viewDetailP2P(
  token: string,
  orderId: string,
): Promise<LambdaAPIResponse<P2PResponse<P2P_STATUS>, unknown>> {
  const body = {
    auth: {
      token,
    },
    order: {
      id: orderId,
    },
  };
  const response = await API.post('/rpc/p2p/detail', body);
  return response.data;
}

export async function viewTransactionDetail(
  transactionId: string,
  sourceId: string,
): Promise<LambdaAPIResponse<Conversion<BELIBIT_INSTANT_STATUS>, unknown>> {
  const body = {
    auth: {
      sourceId: sourceId
    },
    conversion: {
      id: transactionId
    }
  };
  const response = await API.post('rpc/convert/view', body);
  return response.data;
}

export async function loginWhatsapp(
  phoneNumber: string,
  otp: string,
  transactionId: string,
): Promise<LambdaAPIResponse<OTPResponse & { token: string }, unknown>> {
  const body = {
    auth: {
      phoneNumber: phoneNumber,
    },
    otp,
    identifier: transactionId
  };
  const response = await API.post('rpc/instant/login', body);
  return response.data;
}

export async function markAsPaidP2P(
  token: string,
  orderId: string,
): Promise<LambdaAPIResponse<P2PResponse<P2P_STATUS>, unknown>> {
  const body = {
    auth: {
      token,
    },
    order: {
      id: orderId,
    },
  };
  const response = await API.post('/rpc/p2p/paid', body);
  return response.data;
}