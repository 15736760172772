import { Button } from 'antd';
import React from 'react';

const ButtonCustom = ({ children, onClick, disabled = false, className='' }) => {
  return (
    <>
      <Button disabled={disabled} className={`button-custom ${className}`} onClick={onClick}>
        {children}
        <img src='/assets/images/arrow-right.png' alt='arrow-right' />
        <style jsx>
          {`
          .button-custom {
            width: 100%;
            margin-top: 16px;
            height: 60px;
            border-radius: 40px;
            background: #3385FF;
            color: #fff;
            align-items: center;
            display: flex;
            flex-direction: row;
            justify-content: center;
            max-width: 300px;
            min-width: 200px;
            align-self: center;
            transition: all 0.5s;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
          }

          .button-custom > img {
            margin-left: 16px;
            opacity: 0;
            visibility: hidden;
            transition: visibility 0s, opacity 0.5s linear;
          }

          .button-custom:hover, .button-custom:active, .button-custom:focus {
            background: #3385FF;
            color: #fff;
          }

          .button-custom > span {
            margin-left: 46px;
            transition: all 0.5s;
          }

          .button-custom:hover > img {
            opacity: 1;
            visibility: visible;
          }

          .button-custom:hover > span {
            margin-left: 0;
          }
        `}
        </style>
      </Button>
    </>
  );
};

export default ButtonCustom;
