import { message } from 'antd';
import React, { useContext } from 'react';
import { useEffect } from 'react';
import { AppContext } from '../../state/appContext';
import { ACTION_TYPES } from '../../state/actionTypes';
import { CHAIN_ID } from '../../constants';
import TextArea from 'antd/lib/input/TextArea';
import { hooks, metaMask } from '../connectors/metamask';

const { useChainId, useAccounts, useIsActivating, useIsActive,
  // useProvider, useENSNames
} = hooks;

const InputLabel = ({ leftText, rightTexts, value, onChange, placeholder, suffixIcon, disabled }) => {
  const chainId = useChainId();
  const accounts = useAccounts();
  const isActivating = useIsActivating();
  const isActive = useIsActive();

  const { dispatch, state } = useContext(AppContext);

  useEffect(() => {
    if (isActive && state.outputNetwork !== 'BTC') {
      dispatch({ type: ACTION_TYPES.UPDATE_WALLET_ADDRESS, payload: accounts[0] });
    }
  }, [isActive, accounts, dispatch, state.outputNetwork,]);

  const handleConnectMetaMask = (value) => {
    if (value.toUpperCase() === 'CONNECT') {
      if (isActivating) {
        message.error('Please open MetaMask extension to  continue connect');

        return;
      }

      metaMask
        .activate(chainId)
        .then(() => console.log(undefined))
        .catch(e => console.log(e));
    }

    if (value.toUpperCase() === 'METAMASK') {
      if (metaMask?.deactivate) {
        void metaMask.deactivate();
      } else {
        void metaMask.resetState();
      }
      dispatch({ type: ACTION_TYPES.UPDATE_WALLET_ADDRESS, payload: '' });
    }
  };

  const handleChangeChainId = () => {
    if (CHAIN_ID[state.outputNetwork] === chainId) {
      if (metaMask?.deactivate) {
        void metaMask.deactivate();
      } else {
        void metaMask.resetState();
      }
      dispatch({ type: ACTION_TYPES.UPDATE_WALLET_ADDRESS, payload: '' });
    } else {
      metaMask
        .activate(CHAIN_ID[state.outputNetwork])
        .then(() => console.log(undefined))
        .catch(e => console.log(e));
    }
  };

  return (
    <div className='input-label'>
      <div className='input-label__text'>
        <p className='input-label__text__left'>{leftText}</p>
        <div className='input-label__text__right'>
          {rightTexts &&
            rightTexts.map((text, index) => {
              return <p key={index} className={`input-label__text__right-${index} text-right-${text.replace(' ', '-')} input-label__text__right-${index}-${CHAIN_ID[state.outputNetwork] === chainId ? 'green' : 'red'}`} onClick={() => handleConnectMetaMask(text)}>{text}</p>;
            })
          }
        </div>
      </div>

      <div className='input-label__text-area'>
        <TextArea
          className='input-label__input'
          value={value}
          onChange={(e) => onChange(e.target.value)}
          placeholder={placeholder}
          autoSize
          disabled={disabled}
        />
        <div className='input-label__icons'>
          {(suffixIcon && isActive) && <button className='input-label__icons--button' onClick={handleChangeChainId}>{CHAIN_ID[state.outputNetwork] === chainId ? 'CHANGE' : 'FIX CHAIN'}</button>}
          {suffixIcon && <img alt="suffix-icon" src={suffixIcon} className="suffix-icon" />}
        </div>
      </div>


      <style jsx>{`
        .input-label {
          margin-top: 16px;
        }
        .input-label__text {
          font-size: 12px;
          display: flex;
          justify-content: space-between;
        }

        .input-label__text p {
          margin-bottom: 6px;
        }

        .input-label__text__left {
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          color: #000000;
        }

        .input-label__text__right {
          display: flex;
          flex-direction: row;
        }

        .input-label__text__right-0 {
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          background: #CAFBEC;
          border-radius: 4px;
          color: #2FD6A4;
          padding: 2px 8px;
          cursor: pointer;
          border-radius: 4px;
        }

        .input-label__text__right-1 {
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          color: #E7BA15;
          margin-left: 8px;
          background: #FFF6D6;
          padding: 2px 8px;
          border-radius: 4px;
        }

        .input-label__text__right-0-red {
          color:#FFFFFF;
          background:#F07B7B;
        }

        .input-label__text__right-0-green {
          color:#2FD6A4;
          background:#CAFBEC;
        }

        .input-label__input {
          border-radius: 10px;
          border: 1px solid #E7E7E7;
          height: 50px;
        }

        .text-right-Connect {
          background: #EFEFEF;
          border-radius: 4px;
          color: #9F9F9F;
        }

        .ant-input-suffix > img {
          height: 25px;
        }

        .text-right-BSC-NETWORK {
          color: #E7BA15;
          background: #FFF6D6;
        }

        .text-right-ETHEREUM-NETWORK {
          color: #6F7BBA;
          background: #D8DDF7;
        }

        .text-right-POLYGON-NETWORK {
          color: #8247E5;
          background: #E6D7FF;
        }

        .text-right-BITCOIN-NETWORK {
          color: #F7931A;
          background: #FFEAD0;
          cursor: auto;
        }

        .text-right-MAINNET-NETWORK {
          color: #28244A;
          background: #C8C5DD;
        }

        .text-right-ZIL-NETWORK {
          color: #29CCC4;
          background: #C8FFFC;
        }

        .text-right-SOLANA-NETWORK {
          color: #1BDA8E;
          background: #E3FFF3;
        }

        .text-right-AVAXCHAIN-NETWORK {
          color: #E84142;
          background: #FFE3E4;
        }

        .text-right-OPTIMISM-NETWORK {
          color: #C91529;
          background: #FFBEC5;
        }

        .text-right-ALGORAND-NETWORK {
          color: #242424;
          background: #D0D0D0;
        }

        .text-right-ARBITRUM-NETWORK {
          color: #26A0F2;
          background: #D9E3F5;
        }

        .input-label__text-area {
          display: flex;
          border: 1px solid #E7E7E7;
          justify-content: center;
          align-items: center;
          padding: 4px 8px 4px 0;
          border-radius: 10px;
          min-height: 50px;
        }

        .input-label__text-area > textarea.ant-input, .input-label__text-area > textarea.ant-input:focus {
          border: none;
          box-shadow: none;
          background: #ffffff;
        }

        .suffix-icon {
          height: 25px;
        }

        .input-label__icons {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: center;
        }

        .input-label__icons--button {
          width: 60px;
          height: 16px;
          background: #3385FF;
          border-radius: 4px;
          color: #FFFFFF;
          font-weight: 400;
          font-size: 10px;
          line-height: 12px;
          border: none;

          margin-bottom: 4px;
          cursor: pointer;
        }
      `}
      </style>
    </div>
  );
};

export default InputLabel;
