import React from 'react';
import { useNavigate } from 'react-router-dom';
import ButtonCustom from '../../components/ButtonCustom';
import Header from '../../components/Header';

const VerifyWhatsapp = () => {
  const navigate = useNavigate();

  const handlePreviousPage = () => {
    navigate(-1);
  };

  const handleSubmit = () => {
    navigate('/buy-crypto');
  };

  return (
    <>
      <div className='verify-whatsapp'>
        <Header title='Verify WhatsApp' onPreviousPage={handlePreviousPage} />
        <div className='verify-whatsapp__content'>
          <div className='verify-whatsapp__content-img'>
            <img src="/assets/icons/verify-whatsapp.svg" alt="verify-whatsapp" />
          </div>
          <div className='verify-whatsapp__content-text'>
            <span className='verify-whatsapp__content-text__title'>Verification</span>
            <span className='verify-whatsapp__content-text__description'>Next, follow instructions sent to your WhatsApp number.</span>
          </div>
        </div>
        <ButtonCustom onClick={handleSubmit}>OK</ButtonCustom>
      </div>
      <style jsx>
        {`
          .verify-whatsapp {
            padding: 24px;
            display: flex;
            flex-direction: column;
            height: 100%;
          }
          
          .verify-whatsapp__content {
            margin-top: 40px;
            display: flex;
            flex-direction: column;
            flex-grow: 1;
          }
          
          .verify-whatsapp__content-img {
            border-radius: 50%;
            background: #E9F2FF;
            width: 116px;
            height: 116px;
            display: flex;
            justify-content: center;
            align-items: center;
            align-self: center;
          }
          
          .verify-whatsapp__content-text {
            text-align: center;
            display: flex;
            flex-direction: column;
            padding: 0 16px;
            margin-top: 28px;
          }
          
          .verify-whatsapp__content-text__title {
            font-weight: 600;
            font-size: 18px;
            line-height: 27px;
            margin-bottom: 8px;
          }
          
          .verify-whatsapp__content-text__description {
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #777777;
          }
        `}
      </style>
    </>
  );
};

export default VerifyWhatsapp;
