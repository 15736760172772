import { ACTION_TYPES } from "./actionTypes";
import { initialState } from "./initialState";
import React from 'react';
import { ReducerAction } from './interfaces';

export const appReducer: React.Reducer<typeof initialState, ReducerAction> = (
  state: typeof initialState = initialState,
  action: ReducerAction,
) => {
  switch (action.type) {
    case ACTION_TYPES.UPDATE_INPUT_AMOUNT:
      return {
        ...state,
        inputAmount: action.payload.inputAmount,
        outputAmount: action.payload.outputAmount,
        minerFee: action.payload.minerFee,
        fees: action.payload.fees,
        totalFee: action.payload.totalFee,
        price: `${action.payload.price} ${state.inputCurrency} = 1 ${state.outputCurrency}`,
        rate: `${action.payload.price}`,
        totalFeeAmount: action.payload.totalFeeAmount,
        error: "",
        usdValue: action.payload.usdValue,
      };
    case ACTION_TYPES.UPDATE_OUTPUT_CURRENCY:
      return {
        ...state,
        outputCurrency: action.payload.iso,
        outputNetwork: action.payload.network,
      };
    case ACTION_TYPES.UPDATE_INPUT_CURRENCY:
      return {
        ...state,
        inputCurrency: action.payload.id,
      };
    case ACTION_TYPES.UPDATE_WALLET_ADDRESS:
      return {
        ...state,
        walletAddress: action.payload,
      };
    case ACTION_TYPES.UPDATE_WHATSAPP_ID:
      return {
        ...state,
        whatsappID: action.payload,
      };
    case ACTION_TYPES.UPDATE_TRANSACTION_ID:
      return {
        ...state,
        transactionId: action.payload,
      };
    case ACTION_TYPES.UPDATE_ERROR:
      return {
        ...state,
        error: action.payload,
        outputAmount: "",
        usdValue: null,
      };
    case ACTION_TYPES.UPDATE_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: action.payload,
      };
    case ACTION_TYPES.UPDATE_ORDER:
      return {
        ...state,
        orderId: action.payload.orderId,
        sourceId: action.payload.sourceId,
        deposit: action.payload.deposit,
        receive: action.payload.receive,
      };
    case ACTION_TYPES.UPDATE_ORDER_ID:
      return {
        ...state,
        orderId: action.payload,
      };
    case ACTION_TYPES.UPDATE_TXID:
      return {
        ...state,
        txId: action.payload,
      };
    case ACTION_TYPES.UPDATE_OUTPUT_AMOUNT:
      return {
        ...state,
        outputAmount: action.payload,
      };
    case ACTION_TYPES.UPDATE_USD_VALUE:
      return {
        ...state,
        usdValue: action.payload,
      };
    case ACTION_TYPES.UPDATE_INPUT:
      return {
        ...state,
        inputAmount: action.payload,
      };
    case ACTION_TYPES.UPDATE_DESTINATION_ADDRESS:
      return {
        ...state,
        destinationAddress: action.payload,
      };
    case ACTION_TYPES.UPDATE_CURRENCY_DEFAULT:
      return {
        ...state,
        isDefaultSet: action.payload,
      };
    case ACTION_TYPES.UPDATE_REDEEM_CODE:
      return {
        ...state,
        redeemCode: action.payload,
      };
    case ACTION_TYPES.UPDATE_REFERRAL:
      return {
        ...state,
        referral: action.payload,
      };
    default:
      return state;
  }
};

