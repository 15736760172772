import { ArrowRightOutlined, CloseOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MENU_ITEMS } from '../../constants';
import { SESSION_STORAGE_IDS } from '../../helper/enums';

const Menu = () => {
  const navigate = useNavigate();
  const [userId, setUserId] = useState('');

  useEffect(() => {
    const id = sessionStorage.getItem(SESSION_STORAGE_IDS.USER_ID);
    if (id) {
      setUserId(id);
    }
  }, []);

  const handleLogout = () => {
    sessionStorage.removeItem(SESSION_STORAGE_IDS.USER_ID);
    navigate('/buy-crypto');
  };

  const handleRouting = (e, item) => {
    if (item?.name === 'Login' && userId !== '') {
      e.preventDefault();
    }
  };

  return (
    <div className='menu'>
      <div className='menu__header'>
        <span className='menu__header__title'>Menu</span>

        <div className='menu__header__icon' onClick={() => navigate(-1)}>
          <CloseOutlined />
        </div>
      </div>

      <div className='menu__content'>
        {MENU_ITEMS.map((item, index) => (
          <a className='menu__content__item' target={item.target} href={item.link} rel="noreferrer" key={index} onClick={(e) => handleRouting(e, item)}>
            <div className='menu__content__item__left'>
              <img className='menu__content__item__left__icon' alt='menu-item-icon' src={`assets/images/${item.icon}.png`} />
              <span>{`${(item.name === 'Login' && userId !== '') ? 'Logged in as ' + userId : item.name}`}</span>
            </div>
            {(item.name !== 'Login' || userId === '') && <div className='menu__content__item__right'>
              <ArrowRightOutlined />
            </div>}
          </a>
        ))}
        {
          userId !== '' && (
            <div className='menu__content__item' onClick={handleLogout}>
              <div className='menu__content__item__left'>
                <img className='menu__content__item__left__icon' alt='menu-item-icon' src={`assets/images/logout.png`} />
                <span>Logout</span>
              </div>
              <div className='menu__content__item__right'>
                <ArrowRightOutlined />
              </div>
            </div>
          )
        }
      </div>

      <style jsx>{`
        .menu {
          padding: 24px;
        }

        .menu__header {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          justify-items: center;
        }

        .menu__header__title {
          font-weight: 600;
          font-size: 18px;
        }

        .menu__header__icon {
          font-size: 18px;
        }

        .menu__content {
          margin-top: 24px;
        }

        .menu__content__item {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          border: 1px solid rgba(0,0,0,.125);
          border-bottom: none;
          padding: 12px 20px;
          color: #000;
          cursor: pointer;
        }

        .menu__content__item:last-child {
          border-bottom: 1px solid rgba(0,0,0,.125);
        }

        .menu__content__item__left__icon {
          margin-right: 12px;
        }

        a:hover {
          color: #000;
        }
        `}
      </style>
    </div>
  );
};

export default Menu;
