import React from 'react';

/**
 * @param {{ leftText: React.JSX.Element, rightText?: React.JSX.Element }} param0
 * @returns
 */
const OrderDetailItem = ({ leftText, rightText }) => {
  return (
    <>
      <div className='order-detail-item'>

        <p className='order-detail-item__left'>{leftText}</p>
        <p className='order-detail-item__right'>{rightText}</p>

        <style jsx>{`
        .order-detail-item {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          border-bottom: 1px solid #E7E7E7;
          margin-top: 12px;
        }

        .order-detail-item__left {
          font-size: 12px;
          font-weight: 400;
          color: #777777;
        }

        .order-detail-item__right {
          font-size: 12px;
          font-weight: 400;
          color: #777777;
        }
      `}
        </style>
      </div>
    </>
  );
};

export default OrderDetailItem;
