import { Checkbox, message, Spin } from 'antd';
import React, { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { loginWhatsapp, initiateP2P, initiateBelibitInstant } from '../../apis';
import ButtonCustom from '../../components/ButtonCustom';
import Header from '../../components/Header';
import InputLabel from '../../components/InputLabel';
import { AppContext } from '../../state/appContext';
import { ACTION_TYPES } from '../../state/actionTypes';
import { SESSION_STORAGE_IDS } from '../../helper/enums';

const LoginWhatsappOtp = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(AppContext);
  const [isChecked, setIsChecked] = useState(false);
  const [loading, setLoading] = useState(false);

  const [otp, setOtp] = useState('');

  const location = useLocation();

  const { type, orderId, fromPromo } = location.state;

  const handlePreviousPage = () => {
    navigate('/login/whatsapp', { state: { type, fromPromo: fromPromo ?? false } });
  };

  const handleSubmit = async () => {
    if (!otp) {
      message.error('Please enter your OTP');
    } else if (!isChecked) {
      message.error('Please read and agree with Terms of Use and Privacy Policy');
    } else {
      setLoading(true);
      if (type === 'order') {
        try {
          const data = await initiateBelibitInstant({
            whatsappID: state.whatsappID,
            otp,
            transactionId: state.transactionId,
            inputAmount: state.inputAmount,
            inputCurrency: state.inputCurrency,
            outputCurrency: state.outputCurrency,
            walletAddress: state.walletAddress,
            outputNetwork: state.outputNetwork,
            tag: state.paymentMethod,
            refCode: state.referral,
            redeemCode: state.redeemCode,
          });
          if (!data.data.isSpecialPrice && fromPromo) {
            throw new Error('Kuota diskon kamu sudah terpakai');
          }
          sessionStorage.setItem(SESSION_STORAGE_IDS.USER_ID, state.whatsappID);
          sessionStorage.setItem(SESSION_STORAGE_IDS.ORDER_TOKEN, data.data.token);
          setLoading(false);
          if (data.data.orderId) {
            dispatch({
              type: ACTION_TYPES.UPDATE_ORDER,
              payload: {
                orderId: data.data.orderId,
                sourceId: data.data.sourceId,
                deposit: data.data.deposit,
                receive: data.data.receive,
              },
            });
            navigate('/transaction/ordernumber');
          }
        } catch (error) {
          message.error(error?.response?.data?.error?.message ?? error.message);
          console.log("Create Transaction failed: ", error.response);
          setLoading(false);
        }
      } else if (type === 'login' && orderId) {
        // handle /tx/order/:orderId route
        try {
          const response = await loginWhatsapp(state.whatsappID, otp, state.transactionId);
          setLoading(false);
          sessionStorage.setItem(SESSION_STORAGE_IDS.ORDER_TOKEN, response.data.token);
          sessionStorage.setItem(SESSION_STORAGE_IDS.USER_ID, state.whatsappID);
          navigate(`/transaction/ordernumber/${orderId}`);
        } catch (error) {
          message.error(error.response.data.error.message);
          console.log("Login failed: ", error.response);
          setLoading(false);
        }
      } else {
        try {
          const response = await loginWhatsapp(state.whatsappID, otp, state.transactionId);
          setLoading(false);
          sessionStorage.setItem(SESSION_STORAGE_IDS.ORDER_TOKEN, response.data.token);
          sessionStorage.setItem(SESSION_STORAGE_IDS.USER_ID, state.whatsappID);
          navigate('/buy-crypto');
        } catch (error) {
          message.error(error.response.data.error.message);
          console.log("Login failed: ", error.response);
          setLoading(false);
        }
      }
    }
  };

  const handleSubmitP2P = async () => {
    if (!otp) {
      message.error('Please enter your OTP');
    } else if (!isChecked) {
      message.error('Please read and agree with Terms of Use and Privacy Policy');
    } else {
      setLoading(true);
      if (type === 'order') {
        try {
          const data = await initiateP2P({
            whatsappID: state.whatsappID,
            otp,
            transactionId: state.transactionId,
            inputCurrency: state.inputCurrency,
            inputAmount: state.inputAmount,
            outputCurrency: state.outputCurrency,
            walletAddress: state.walletAddress,
            outputNetwork: state.outputNetwork,
          });
          sessionStorage.setItem(SESSION_STORAGE_IDS.USER_ID, state.whatsappID);
          sessionStorage.setItem(SESSION_STORAGE_IDS.ORDER_TOKEN, data.data.token);
          setLoading(false);
          if (data.data.order.orderId) {
            dispatch({
              type: ACTION_TYPES.UPDATE_ORDER,
              payload: {
                orderId: data.data.order.orderId,
                sourceId: data.data.order.sourceId,
                deposit: data.data.order.deposit,
                receive: data.data.order.receive,
              },
            });
            navigate('/tx/order');
          }
        } catch (error) {
          message.error(error.response.data.error.message);
          console.log("Create Transaction failed: ", error.response);
          setLoading(false);
        }
      } else if (type === 'login' && orderId) {
        // handle /tx/order/:orderId route
        try {
          const response = await loginWhatsapp(state.whatsappID, otp, state.transactionId);
          setLoading(false);
          sessionStorage.setItem(SESSION_STORAGE_IDS.ORDER_TOKEN, response.data.token);
          sessionStorage.setItem(SESSION_STORAGE_IDS.USER_ID, state.whatsappID);
          navigate(`/tx/order/${orderId}`);
        } catch (error) {
          message.error(error.response.data.error.message);
          console.log("Login failed: ", error.response);
          setLoading(false);
        }
      } else {
        try {
          const response = await loginWhatsapp(state.whatsappID, otp, state.transactionId);
          setLoading(false);
          sessionStorage.setItem(SESSION_STORAGE_IDS.ORDER_TOKEN, response.data.token);
          sessionStorage.setItem(SESSION_STORAGE_IDS.USER_ID, state.whatsappID);
          navigate('/buy-crypto');
        } catch (error) {
          message.error(error.response.data.error.message);
          console.log("Login failed: ", error.response);
          setLoading(false);
        }
      }
    }
  };

  return (
    <Spin spinning={loading}>
      <div className='verify-page'>
        <Header title='Verify your whatsapp number' onPreviousPage={handlePreviousPage} />

        <div className='verify-page__content'>
          <div className='verify-page__content__information'>
            <p className='verify-page__content__information-head'>We've sent a verification code to</p>
            <p className='verify-page__content__information-tail'>{state.whatsappID}</p>
          </div>
          <InputLabel leftText='Verification code' value={otp} onChange={setOtp} />
          <Checkbox checked={isChecked} onChange={(e) => setIsChecked(e.target.checked)}>I agree with <a target='_blank' href='https://docs.beli.finance/instant-fiat-onramp/terms-of-service' rel="noreferrer">Terms of Use</a> and <a target='_blank' href='https://docs.beli.finance/instant-fiat-onramp/privacy-policy' rel="noreferrer">Privacy Policy</a></Checkbox>
        </div>

        <ButtonCustom onClick={handleSubmit}>Continue</ButtonCustom>

        <style jsx>{`
        .verify-page {
          padding: 24px;
          min-height: 100%;
          display: flex;
          flex-direction: column;
        }

        .verify-page__content {
          flex-grow: 1;
        }

        .verify-page__content__information {
          font-size: 16px;
          font-weight: 400;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          margin-top: 34px;
        }

        .verify-page__content__information-head {
          color: #777777;
        }

        .verify-page__content__information-tail {
          color: #F79E1B;
        }

        .ant-checkbox-wrapper {
          margin-top: 24px;
        }

        .ant-spin-nested-loading, .ant-spin-container {
          height: 100%;
        }
      `}
        </style>
      </div>
    </Spin>
  );
};

export default LoginWhatsappOtp;
