import { AppState } from './interfaces';

export const initialState: AppState = {
  inputAmount: null,
  inputCurrency: 'IDR',
  outputCurrency: 'USDT',
  outputAmount: null,
  outputNetwork: 'BSC',
  minerFee: null,
  totalFee: null,
  fees: [],
  price: null,
  rate: null,
  totalFeeAmount: null,
  walletAddress: '',
  whatsappID: '',
  orderId: '',
  transactionId: '',
  error: '',
  paymentMethod: 'MANDIRI',
  sourceId: null,
  deposit: null,
  txId: null,
  usdValue: null,
  destinationAddress: '',
  receive: null,
  isDefaultSet: false,
  redeemCode: undefined,
  referral: null,
};
