export const isWhitelist = (currency: string): boolean => {
  const whitelists = ['NTP'];
  return whitelists.includes(currency);
};


export const matchWhitelist = (orderId: string): boolean => {
  // basically whitelisted guid format is 1{timestamp}-{random_digits}
  const match = orderId.match(/^1\d{13}-\d+$/);
  return match ? true : false;
};