export enum ACTION_TYPES {
  UPDATE_INPUT_AMOUNT = 'UPDATE_INPUT_AMOUNT',
  UPDATE_OUTPUT_CURRENCY = 'UPDATE_OUTPUT_CURRENCY',
  UPDATE_INPUT_CURRENCY = 'UPDATE_INPUT_CURRENCY',
  UPDATE_WALLET_ADDRESS = 'UPDATE_WALLET_ADDRESS',
  UPDATE_WHATSAPP_ID = 'UPDATE_WHATSAPP_ID',
  UPDATE_TRANSACTION_ID = 'UPDATE_TRANSACTION_ID',
  UPDATE_ERROR = 'UPDATE_ERROR',
  UPDATE_PAYMENT_METHOD = 'UPDATE_PAYMENT_METHOD',
  UPDATE_ORDER_ID = 'UPDATE_ORDER_ID',
  UPDATE_ORDER = 'UPDATE_ORDER',
  UPDATE_DEPOSIT = 'UPDATE_DEPOSIT',
  UPDATE_TXID = 'UPDATE_TXID',
  UPDATE_OUTPUT_AMOUNT = 'UPDATE_OUTPUT_AMOUNT',
  UPDATE_INPUT = 'UPDATE_INPUT',
  UPDATE_USD_VALUE = 'UPDATE_USD_VALUE',
  UPDATE_DESTINATION_ADDRESS = 'UPDATE_DESTINATION_ADDRESS',
  UPDATE_CURRENCY_DEFAULT = 'UPDATE_CURRENCY_DEFAULT',
  UPDATE_REDEEM_CODE = 'UPDATE_REDEEM_CODE',
  UPDATE_REFERRAL = 'UPDATE_REFERRAL'
}
