import React, { useContext, useEffect, useState } from 'react';
import ButtonCustom from '../../components/ButtonCustom';
import { AppContext } from '../../state/appContext';
import { useNavigate } from 'react-router-dom';
import { URL_TRANSACTION_DETAIL } from '../../constants';
import { message, Steps, Spin } from 'antd';
import { getCryptoList } from '../../apis';
import { SESSION_STORAGE_IDS } from '../../helper/enums';

const { Step } = Steps;

const TransactionCompleted = () => {
  const { state } = useContext(AppContext);
  const [cryptoList, setCryptoList] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (!state.receive || !state.deposit || !state.orderId) {
      navigate('/buy-crypto');
    }

    async function fetchData() {
      setLoading(true);
      const cryptoList = sessionStorage.getItem(SESSION_STORAGE_IDS.CRYPTO_LIST);
      if (!cryptoList) {
        try {
          const response = await getCryptoList();
          sessionStorage.setItem(SESSION_STORAGE_IDS.CRYPTO_LIST, JSON.stringify(response.data));
          setCryptoList(response.data);
          setLoading(false);
        } catch (error) {
          /** empty */
        }
      } else {
        setCryptoList(JSON.parse(cryptoList));
        setLoading(false);
      }
    }

    fetchData();
  }, [navigate, state]);

  const handleBackHome = () => {
    navigate('/buy-crypto');
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(`${window.location.host}/transaction/ordernumber/${state?.orderId}`);
    message.success('copied!');
  };

  const getCurrency = () => {
    return cryptoList.find(item => item.iso.toUpperCase() === state.receive?.currency?.toUpperCase() && item.network === state.receive?.network);
  };

  const shortenAddress = (address) => {
    if (address === null || typeof address === 'undefined') {
      return '';
    }
    if (address.length < 10) {
      return address;
    }
    const mid = Math.floor(address.length / 2);
    const before = address.substring(0, mid - 1);
    const after = address.substring(mid + 2);

    return `${before}...${after}`;
  };

  // const splitAddress = (address) => {
  //   if (address === null || typeof address === 'undefined') {
  //     return [];
  //   }
  //   const mid = Math.floor(address.length / 2);
  //   const firstHalf = address.slice(0, mid);
  //   const secondHalf = address.slice(mid, address.length);
  //   return [firstHalf, secondHalf];
  // };

  return (
    <Spin spinning={loading}>
      {!loading && <div className='transaction-completed'>
        <Steps current={1} labelPlacement="vertical" responsive={false}>
          <Step title="Ordered" status="process" />
          <Step title="Processing" status="process" />
          <Step title="Completed" status="process" />
        </Steps>
        <div className='transaction-completed__content'>
          <div className='transaction-completed__img'>
            <img src='/assets/images/completed.png' alt='transaction-completed' />
          </div>
          <div className='transaction-completed__text'>
            <h1 className='transaction-completed__text--title'>Transaction Completed</h1>
            <p className='transaction-completed__text--description'>We have completed your transaction. <br />
              View details on <a className='text-underline' href={`${URL_TRANSACTION_DETAIL[state.receive?.network]}${state?.txId}`} target="_blank" rel="noreferrer">block explorer</a>.</p>
          </div>
          <div className='transaction-completed__detail'>
            <div className='transaction-completed__detail--amount'>
              <div className='transaction-completed__detail--amount-input'>
                <img src='/assets/images/IDR.png' alt='icon-amount' />
                {(+state.deposit?.amount).toLocaleString()} {state.deposit?.currency}
              </div>
              <img src='/assets/images/next.png' alt='arrow-right' />
              <div className='transaction-completed__detail--amount-output'>
                <img src={getCurrency()?.iconUrl?.small} alt='icon-amount' />
                {`${state.receive?.amount} ${state.receive?.currency}`}
              </div>
            </div>
            <div className='transaction-completed__detail--information'>
              <p>
                <a href={`${URL_TRANSACTION_DETAIL[state.receive?.network]}${state?.txId}`} target="_blank" rel="noreferrer">
                  {/* <span className='transaction-completed__detail--information-address'
                    data-content-start={splitAddress(state?.destinationAddress)?.[0] ?? ''}
                    data-content-end={splitAddress(state?.destinationAddress)?.[1] ?? ''}
                  >
                  </span> */}
                  {shortenAddress(state?.destinationAddress)}
                </a>
                <br />
                <span onClick={handleCopyLink}>Invoice: {state.orderId}</span>
              </p>
            </div>
          </div>
        </div>
        <ButtonCustom onClick={handleBackHome}>OK</ButtonCustom>
        <ButtonCustom onClick={handleBackHome}>Order again</ButtonCustom>

      </div>}
      <style jsx>
        {`
          .transaction-completed {
            padding: 40px 24px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
          }

          .transaction-completed__content {
            flex-grow: 1;
            margin-top: 18px;
          }

          .transaction-completed__img {
            display: flex;
            justify-content: center;
            margin-bottom: 24px;
          }

          .transaction-completed__text {
            display: flex;
            flex-direction: column;
            text-align: center;
          }

          .transaction-completed__text--title {
            font-weight: 600;
            font-size: 18px;
            line-height: 27px;
            margin-bottom: 8px;
          }

          .transaction-completed__text--description {
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #777777;
          }

          .transaction-completed__detail {
            background: #FFFFFF;
            border: 1px solid #E7E7E7;
            border-radius: 10px;
            height: 135px;
            padding: 24px 12px;
          }

          .transaction-completed__detail--amount {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          .transaction-completed__detail--amount img {
            width: 16px;
            height: 16px;
            margin-right: 8px;
          }

          .transaction-completed__detail--amount div {
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
          }

          .transaction-completed__detail--information {
            text-align: center;
            margin-top: 24px;
            color: #777777;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
          }

          .transaction-completed__detail--information a {
            color: #777777;
          }

          .transaction-completed__detail--information span {
            cursor: pointer;
          }

          .transaction-completed > button:last-child {
            background-color: #fff;
            color: #3385FF;
            border: none;
            box-shadow: none;
          }

          .text-underline {
            text-decoration: underline;
            color: #777777;
            cursor: pointer;
          }

          .text-underline:hover {
            color: #777777;
          }

          .ant-steps-item-process .ant-steps-item-icon {
            background: #29D664 !important;
            border-color: #29D664 !important;
          }

          .ant-steps-item-process .ant-steps-item-title {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #777777 !important;
          }

          .ant-spin-nested-loading, .ant-spin-container {
            height: 100%;
          }

          .transaction-completed__detail--information-address::before,
          .transaction-completed__detail--information-address::after {
            display: inline-block;
            max-width: 50%;
            overflow: hidden;
            white-space: pre;
          }

          .transaction-completed__detail--information-address::before {
            content: attr(data-content-start);
            text-overflow: ellipsis;
          }

          .transaction-completed__detail--information-address::after {
            content: attr(data-content-end);
            text-overflow: '';
            direction: rtl;
          }
        `}
      </style>
    </Spin>
  );
};

export default TransactionCompleted;
